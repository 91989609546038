import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Item.module.sass";
import Icon from "../../Icon";
import PhotoView from "../../PhotoView";

const Item = ({ className, item, index, gallery }) => {
  const [initialSlide, setInitialSlide] = useState(0);
  const [visible, setVisible] = useState(false);

  const handleOpen = (index) => {
    setInitialSlide(index);
    setVisible(true);
  };
  return (
    // <Link className={cn(className, styles.item)} to={item.url}>
    <>
      <div onClick={handleOpen} className={styles.preview}>
        <img srcSet={`${item.srcSet}`} src={item.src} alt={item.title} />
        {/* <div
          className={cn(
            { "status-black": item.category === "black" },
            styles.category
          )}
        >
          {item.categoryText}
        </div> */}
      </div>
      <PhotoView
        title="Browse Unfiltered Photos"
        initialSlide={index}
        visible={visible}
        items={gallery}
        onClose={() => setVisible(false)}
      />

      {/* <div className={styles.title}>{item.title}</div>
      <div className={styles.counter}>
        <Icon name="home" size="16" />
        {item.counter}
      </div> */}
    </>
  );
};

export default Item;
