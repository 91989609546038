import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Description.module.sass";
import { Link, useHistory } from "react-router-dom";
import Icon from "../../../components/Icon";
import Details from "./Details";
import Receipt from "../../../components/Receipt";
import axiosInstance from "../../../utils/axiosInstance";
import axios from "axios";
import Counter from "../../../components/Counter";
import Dropdown from "../../../components/Dropdown";

const items = [
  {
    title: "May 15, 2021 ",
    category: "Date",
    icon: "calendar",
  },
  // {
  //   title: "2 guests",
  //   category: "Guest",
  //   icon: "user",
  // },
];

const Options = ["Newest", "Popular"];

const receipt = [
  {
    title: "Original Price",
    content: "₹20,000/-",
  },
  {
    title: "30% Early Bird Discount",
    content: "₹13,500/-",
  },
  {
    title: "Service fee",
    content: "₹500/-",
  },
  {
    title: "Total",
    content: "₹14,000/-",
  },
];

const Description = ({ classSection, sport, vendor }) => {
  console.log({ vendor });
  const [service, setService] = useState(0);
  const [type, setPriceType] = useState("learn");
  const [prices, setPrices] = useState(vendor.prices);
  const [select, setSelect] = useState(Object.keys(prices["learn"])[0]);
  const [seat, setSeat] = useState(0);
  const history = useHistory();
  const btnRef = useRef();

  const onClick = () => {
    history.push({
      pathname: `/event-details/${sport}/${vendor.slug}/checkout`,
      state: {
        activity_name: sport,
        activity_category: type,
      },
    });
  };
  useEffect(() => {
    console.log("select >>>>>>>>>>", { select });
  }, [select]);

  const onPriceClick = (tag) => {
    let btns = document.querySelectorAll(".tagBtn");
    for (let node of btns) {
      node.style.background = "#353945";
    }
    let _default = Object.keys(prices[tag])[0];
    setSelect(_default);
    console.log("default>>>>>>", _default);
    setPriceType(tag);
    console.log("tag>>>>>>", tag);
    let btnNode = document.getElementById(tag);
    btnNode.style.background = "#3B71FE";
  };

  const controls = [
    {
      title: "Payable on collection",
      content:
        "If you reserve any of these extras, you'll pay for them at the counter.",
      value: service,
      setValue: setService,
    },
    {
      title: "Child seat",
      content: "$50",
      value: seat,
      setValue: setSeat,
    },
  ];

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <Details vendor={vendor} className={styles.details} />
          <Receipt
            className={styles.receipt}
            items={items}
            // priceOld="₹20K"
            priceActual={prices[type][select]}
            time="per head"
          >
            {/* <div className={styles.box}>
              <div className={styles.category}>Extras</div>
              <div className={styles.group}>
                {controls.map((x, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.wrap}>
                      <div className={styles.subtitle}>{x.title}</div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                    <Counter
                      className={styles.counter}
                      value={x.value}
                      setValue={x.setValue}
                      iconMinus="minus"
                      iconPlus="plus"
                    />
                  </div>
                ))}
              </div>
            </div> */}

            <div className={styles.tag_item}>
              <button
                className={cn("button", styles.tag_button, "tagBtn")}
                onClick={() => onPriceClick("learn")}
                id="learn"
                style={{ backgroundColor: "#3B71FE" }}
                ref={btnRef}
              >
                <span>Learn</span>
              </button>
              <button
                className={cn("button", styles.tag_button, "tagBtn")}
                onClick={() => onPriceClick("leisure")}
                id="leisure"
                style={{ backgroundColor: "#353945" }}
                ref={btnRef}
              >
                <span>Leisure</span>
              </button>
              <button
                className={cn("button", styles.tag_button, "tagBtn")}
                onClick={() => onPriceClick("rent")}
                id="rent"
                style={{ backgroundColor: "#353945" }}
                ref={btnRef}
              >
                <span>Rent</span>
              </button>
            </div>
            <div className={styles.box}>
              <Dropdown
                className={styles.dropdown}
                value={select}
                setValue={setSelect}
                options={Object.keys(prices[[type]])}
              />
            </div>
            <div className={styles.pay}>
              <div>
                <Link
                  to={{
                    pathname: `/event-details/${sport}/${vendor.slug}/checkout`,
                    state: {
                      activity_name: sport,
                      activity_type: type,
                      activity_category: select,
                      activity_cost: prices[type][select],
                    },
                  }}
                >
                  <button
                    className={cn("button", styles.button)}
                    // onClick={handleReceipt}
                  >
                    <span>Proceed to Pay</span>
                    <Icon name="bag" size="16" />
                  </button>
                </Link>
              </div>
              <div className={styles.note}>
                <Icon name="idea" size="12" />
                You won’t be charged
              </div>
            </div>
            <div className={styles.box}>
              {/* <div className={styles.category}>Price details</div> */}
              {/* <div className={styles.table}>
                {receipt.map((x, index) => (
                  <div className={styles.line} key={index}>
                    <div className={styles.cell}>{x.title}</div>
                    <div className={styles.cell}>{x.content}</div>
                  </div>
                ))}
              </div> */}
              <div className={styles.foot}>
                <div className={styles.rating}>
                  <Icon name="star" size="20" />
                  <div className={styles.number}>4.8</div>
                  {/* <div className={styles.reviews}>(256 reviews)</div> */}
                </div>
                <div className={styles.note}>
                  <Icon name="phone" size="12" />
                  Request Callback
                </div>
              </div>
            </div>
          </Receipt>
        </div>
      </div>
    </div>
  );
};

export default Description;
