import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../../../../components/Icon";

const Card = ({ className, item }) => {
  return (
    <Link
      className={cn(className, styles.card)}
      to={`/event-category/${item.url}`}
    >
      <div className={styles.preview}>
        <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Card" />
      </div>
      <div className={styles.body}>
        <div className={styles.line}>
          <div className={styles.subtitle}>{item.title}</div>
          <div className={styles.location}>{item.location}</div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
