import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ConfirmAndPay.module.sass";
import CreditCard from "./CreditCard";
import PayPal from "./PayPal";
import Icon from "../Icon";
import Dropdown from "../Dropdown";
import TextInput from "../TextInput";
import axios from "axios";
const nav = ["UPI (Free)"];

// const logins = ["tam@ui8.net", "vova@ui8.net", "john@ui8.net"];

const ConfirmAndPay = ({
  className,
  guests,
  title,
  buttonUrl,
  activity_name,
  activity_type,
  activity_category,
  activity_cost,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  // const [login, setLogin] = useState(logins[0]);

  useEffect(() => {
    let btnNode = document.getElementById(activity_type);
    btnNode.style.background = "#3B71FE";
  }, []);

  return (
    <div className={cn(className, styles.confirm)}>
      <div className={cn("h2", styles.title)}>Book an Enquiry</div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.box}>
            <div className={styles.category}>{title}</div>
            <div className={styles.group}>
              <div className={styles.option}>
                <div className={styles.info}>Dates</div>
                <input className={styles.input} type="text" />
                <div className={styles.value}>May 15 - 22, 2021</div>
                <button className={styles.edit}>
                  <Icon name="edit" size="24" />
                </button>
              </div>
              {/* {guests && (
                <div className={styles.option}>
                  <div className={styles.info}>Guests</div>
                  <input className={styles.input} type="text" />
                  <div className={styles.value}>2 guest</div>
                  <button className={styles.edit}>
                    <Icon name="edit" size="24" />
                  </button>
                </div>
              )} */}
            </div>
          </div>
          <div className={styles.tag_item}>
            <button
              className={cn("button", styles.tag_button, "tagBtn")}
              id="learn"
              style={{ backgroundColor: "#353945" }}
            >
              <span>Learn</span>
            </button>
            <button
              className={cn("button", styles.tag_button, "tagBtn")}
              id="leisure"
              style={{ backgroundColor: "#353945" }}
            >
              <span>Leisure</span>
            </button>
            <button
              className={cn("button", styles.tag_button, "tagBtn")}
              id="rent"
              style={{ backgroundColor: "#353945" }}
            >
              <span>Rent</span>
            </button>
          </div>
          <div className={styles.box}>
            <div className={cn(styles.dropdown, className)}>
              <div className={styles.head}>
                {/* <div className={styles.selection}>{value}</div> */}
                <div className={styles.arrow}>
                  <Icon name="arrow-bottom" size="10" />
                </div>
              </div>
              <div className={styles.body}>
                {/* {options.map((x, index) => ( */}
                <div
                  className={cn(styles.option)}
                  // className={cn(styles.option, {
                  //   [styles.selectioned]: x === value,
                  // })}
                  // onClick={() => handleClick(x, index)}
                  // key={index}
                >
                  {activity_category}
                </div>
                {/* ))} */}
              </div>
            </div>
          </div>
          {/* <div className={styles.box}>
            <div className={styles.top}>
              <div className={styles.category}>Pay with</div>
              <div className={styles.nav}>
                {nav.map((x, index) => (
                  <button
                    className={cn(styles.link, {
                      [styles.active]: index === activeIndex,
                    })}
                    key={index}
                    onClick={() => setActiveIndex(index)}
                  >
                    {x}
                  </button>
                ))}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>saved contact info</div>
              <Dropdown
                className={styles.dropdown}
                value={login}
                setValue={setLogin}
                options={logins}
              />
            </div>
          </div> */}
        </div>
        <div className={styles.item}>
          {activeIndex === 0 && (
            <CreditCard className={styles.credit} buttonUrl={buttonUrl} />
          )}
          {/* {activeIndex === 1 && (
            <PayPal className={styles.paypal} buttonUrl={buttonUrl} />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ConfirmAndPay;
