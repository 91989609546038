import React, { useState } from "react";
import cn from "classnames";
import styles from "./Login.module.sass";
import Icon from "../Icon";
import Form from "../Form";
import TextInput from "../TextInput";
import Radio from "../Radio";
//react google login imports
import { GoogleLogin } from "react-google-login";
import { useGoogleLogin } from "react-google-login";
import GoogleButton from "react-google-button";
import axios from "axios";
import Password from "../Password";
import MobileNo from "../MobileNo";
import axiosInstance from "../../utils/axiosInstance";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Login = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState(false);
  const [phoneModal, setPhoneModal] = useState(false);
  const [confirmPhone, setConfirmPhone] = useState(true);

  const onSuccess = async (res) => {
    console.log("Google id token >>>", res.tokenId);
    try {
      const postBody = { token_id: res.tokenId };
      const response = await axiosInstance.post("/v1/oauth/google", postBody);
      const token = response.data.tokens.access;
      localStorage.setItem("token", token);
      console.log(response.data.tokens.access);
      axiosInstance.defaults.headers.common["Authorization"] =
        "Bearer " + token;
    } catch (error) {
      console.log(error);
    }
  };

  const onFailure = (res) => {
    console.log(res);
  };

  const handleSubmit = () => {
    setPhoneModal(true);
  };

  const handleSubmit2 = async () => {
    const reqBody = {
      email,
      phone,
    };
    try {
      const response = await axiosInstance.post(
        "/v1/customer/register",
        reqBody
      );
      setMessage(true);
    } catch (error) {
      console.log({ error });
    }
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  return (
    <div className={cn(styles.login)}>
      {!phoneModal && (
        <div className={styles.item}>
          <div className={cn("h3", styles.title)}>
            Login or sign up on Sportify India
          </div>
          <div className={styles.btns}>
            {/* <button className={cn("button", styles.button)}>
            <Icon name="google" size="16" />
            <span>Google</span>
          </button> */}
            <GoogleLogin
              clientId={clientId}
              jsSrc={"https://apis.google.com/js/api.js"}
              buttonText="SignUp"
              render={(renderprop) => (
                // <GoogleButton label={label} onClick={renderprop.onClick} />
                <button
                  className={cn("button", styles.button)}
                  onClick={renderprop.onClick}
                >
                  <Icon name="google" size="16" />
                  <span>Continue with Google</span>
                </button>
                // <button onClick={renderprop.onClick}>
                //   <img
                //     alt={"img"}
                //     width={30}
                //     style={({ marginTop: "3px" }, { marginLeft: "25px" })}
                //     src={
                //       "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                //     }
                //   />
                // </button>
              )}
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              style={{ marginTop: "200px", width: "70%" }}
              isSignedIn={false}
            />
            {/* <button className={cn("button-black", styles.button)}>
            <Icon name="apple" size="16" />
            <span>Apple</span>
          </button> */}
          </div>
          <div className={styles.note}>Or continue with email</div>
          <Form
            className={styles.form}
            value={email}
            setValue={setEmail}
            onSubmit={handleSubmit}
            placeholder="Enter your email"
            type="email"
            name="email"
            icon="arrow-next"
          />
          <div className={styles.foot}>
            Already have an account?{" "}
            <a className={styles.link} href="/#" rel="noopener noreferrer">
              Login
            </a>
          </div>
        </div>
      )}
      {/* <Password /> */}
      {phoneModal && (
        <MobileNo
          handleSubmit={handleSubmit2}
          handleChange={setPhone}
          message={message}
        />
      )}
    </div>
  );
};

export default Login;
