import React from "react";
import styles from "./Terms.module.sass";
import Main from "./Main";

const TermsCondition = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default TermsCondition;
