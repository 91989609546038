import React from "react";
import Main from "./Main";
import Destinations from "../../components/Destinations";
import Planning from "../../components/Planning";
import Catalog from "./Catalog";
import Services from "./Services";
import Faq from "./Faq";
import Brands from "../../components/Brands";
import Categories from "../../components/Categories";

// data
import { destinations } from "../../mocks/destinations";
import { categories2 } from "../../mocks/categories";

const ComingSoon = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default ComingSoon;
