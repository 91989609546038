import React from "react";
import cn from "classnames";
import styles from "./CheckoutForm.module.sass";
import Control from "../../components/Control";
import ConfirmAndPay from "../../components/ConfirmAndPay";
import PriceDetails from "../../components/PriceDetails";
import { useHistory, useLocation, useParams } from "react-router";

const items = [
  {
    title: "May 15, 2021",
    category: "Check-in",
    icon: "calendar",
  },
  {
    title: "May 22, 2021",
    category: "Check-out",
    icon: "calendar",
  },
  {
    title: "2 guests",
    category: "Guest",
    icon: "user",
  },
];

// const table = [
//   {
//     title: "₹10K x 2 guest",
//     value: "₹20,000",
//   },
//   {
//     title: "10% campaign discount",
//     value: "-₹6,000",
//   },
//   {
//     title: "Service fee",
//     value: "$103",
//   },
//   {
//     title: "Total (INR)",
//     value: "₹14,500/-",
//   },
// ];

const CheckoutForm = () => {
  const { sport, vendor } = useParams();
  const history = useHistory();
  const {
    state: { activity_name, activity_category, activity_cost, activity_type },
  } = useLocation();

  const breadcrumbs = [
    {
      title: sport,
      url: `/event-detail/${sport}/${vendor}`,
    },
    {
      title: "Confirm and pay",
    },
  ];

  // const table = [
  //   {
  //     title: name,
  //     value: price,
  //   },
  // ];

  return (
    <div className={cn("section-mb80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <Control
          className={styles.control}
          urlHome="/"
          sport={sport}
          vendor={vendor}
          breadcrumbs={breadcrumbs}
        />
        <div className={styles.wrapper}>
          <ConfirmAndPay
            className={styles.confirm}
            activity_name={activity_name}
            activity_category={activity_category}
            activity_cost={activity_cost}
            activity_type={activity_type}
            title="Your trip"
            buttonUrl="/stays-checkout-complete"
            guests
          />
          {/* <PriceDetails
            className={styles.price}
            more
            image={`/images/gallery/${sport}/${vendor}/1.jpg`}
            title="Discover Surfing in Pondicherry"
            items={items}
            table={table}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
