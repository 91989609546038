import React from "react";
import cn from "classnames";
import styles from "./StaysProduct.module.sass";
import Product from "../../components/Product";
import Description from "./Description";
import CommentsProduct from "../../components/CommentsProduct";
import Browse from "../../components/Browse";
import Categories from "../../components/Categories";
// data
import { browse3 } from "../../mocks/browse";
import { categories2 } from "../../mocks/categories";
import { useHistory, useLocation, useParams } from "react-router";
import vendors from "../../mocks/stays";

const gallery = [
  "/images/gallery/IMG_8045.jpg",
  "/images/gallery/img8.jpg",
  "/images/gallery/IMG_8059.jpg",
  // "/images/gallery/IMG_8394.jpg",
  "/images/gallery/img3.jpg",
];

const options = [
  // {
  //   title: "Best driver",
  //   icon: "user",
  // },
  {
    title: " RMYC, Spring Heaven Warf, The Harbor, Near Marina Beach Chennai.",
    icon: "flag",
  },
];

const parametersUser = [
  {
    title: "Superhost",
    icon: "home",
  },
  {
    title: "256 reviews",
    icon: "star-outline",
  },
];

const socials = [
  {
    title: "twitter",
    url: "https://twitter.com/ui8",
  },
  {
    title: "instagram",
    url: "https://www.instagram.com/ui8net/",
  },
  {
    title: "facebook",
    url: "https://www.facebook.com/ui8.net/",
  },
];

const EventDetail = () => {
  const history = useHistory();
  const location = useLocation();
  var { sport, vendor } = useParams();
  // console.log("location State >>>>>>>", location);
  // const { vendor } = location?.state;
  vendor = vendors[[sport]][[vendor]];

  const breadcrumbs = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: sport.charAt(0).toUpperCase() + sport.slice(1),
      url: `/event-category/${sport}`,
    },
    {
      title: vendor.title,
      url: "",
    },
  ];

  return (
    <>
      <Product
        classSection="section-mb64"
        urlHome="/"
        // title="Discover Surfing in Pondicherry"
        sport={sport}
        title={vendor.title}
        breadcrumbs={breadcrumbs}
        options={options}
        slug={vendor.slug}
        type="stays"
      />
      <Description sport={sport} vendor={vendor} classSection="section" />
      {/* <CommentsProduct
        className={cn("section", styles.comment)}
        parametersUser={parametersUser}
        info="Described by Queenstown House & Garden magazine as having 'one of the best views we've ever seen' you will love relaxing in this newly built"
        socials={socials}
        buttonText="Contact"
      />
      <Browse
        classSection="section"
        classTitle="h2"
        title="Discover more"
        info="for car rentals"
        items={browse3}
      />
      <Categories
        classSection="section"
        title="Recommended pickup locations"
        info="A lot of amazing experiences 🏝"
        items={categories2}
      /> */}
    </>
  );
};

export default EventDetail;
