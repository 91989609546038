import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
// import Dropdown from "../../../../../components/Dropdown";

// const general = [
//   {
//     title: "General",
//     items: [
//       {
//         title:
//           "I have no idea what activities are even happening around me. Are there any?",
//         content:
//           "Oh yess, there’s plenty ;)\
//           Explore all the list of offbeat activities around the city/near you on our platform\
//           Directly book the experience of your choice at your convenience - no hassle\
//           ",
//       },
//       {
//         title: "I’m bored, I want to experience something new on weekends",
//         content:
//           "Do you want to try something relaxing? Something adventurous? Insta friendly? Not quite sure?\
//           We’ll layout all the options based on your interest - for instance a \
//           speedboat ride could be a great way to relax, surfing would be hella adventurous, and so on...",
//       },
//       {
//         title: "I want to learn a new skill but have limited time on my hands",
//         content:
//           "You’re at the right place - we have beginner friendly programs made for first timers\
//           You can sign up for weekend workshops, courses, certifications and more\
//           Be sure to pick the right one based on your interest levels/availability.",
//       },
//       {
//         title: "I’m done of the same old faces. I need new friends. ",
//         content:
//           "Join our community (we need friends too)\
//           We curate mini events - these can be 1 day leisure events, 2 day workshops, or 15 day courses, etc \
//           Idea is to meet like minded folks and create memories.",
//       },
//     ],
//   },
// ];
const general = [
  {
    title:
      "I have no idea what activities are even happening around me. Are there any?",
    content:
      "Oh yess, there’s plenty ;)\
      Explore all the list of offbeat activities around the city/near you on our platform\
      Directly book the experience of your choice at your convenience - no hassle\
      ",
  },
  {
    title: "I’m bored, I want to experience something new on weekends",
    content:
      "Do you want to try something relaxing? Something adventurous? Insta friendly? Not quite sure?\
      We’ll layout all the options based on your interest - for instance a \
      speedboat ride could be a great way to relax, surfing would be hella adventurous, and so on...",
  },
  {
    title: "I want to learn a new skill but have limited time on my hands",
    content:
      "You’re at the right place - we have beginner friendly programs made for first timers\
      You can sign up for weekend workshops, courses, certifications and more\
      Be sure to pick the right one based on your interest levels/availability.",
  },
  {
    title: "I’m done of the same old faces. I need new friends. ",
    content:
      "Join our community (we need friends too)\
      We curate mini events - these can be 1 day leisure events, 2 day workshops, or 15 day courses, etc \
      Idea is to meet like minded folks and create memories.",
  },
];

const Faq = ({ items = general }) => {
  const options = [];
  // items.map((x) => options.push(x.title));

  // const [category, setCategory] = useState(options[0]);
  console.log({ items });

  return (
    <>
      <div className={styles.top}>
        <h2 className={cn("h2", styles.title)}>FAQs</h2>
      </div>
      <div className={styles.list}>
        {/* {items
          .find((x) => x.title === category)
          .items.map((x, index) => (
            <Item className={styles.item} item={x} index={index} key={index} />
          ))} */}
        {items.map((x, index) => (
          <Item className={styles.item} item={x} index={index} key={index} />
        ))}
      </div>
    </>
  );
};

export default Faq;
