import React, { useState } from "react";
import cn from "classnames";
import styles from "./Hosts.module.sass";
import Slider from "react-slick";
import Item from "./Item";
import Icon from "../Icon";
import DropdownEmpty from "../DropdownEmpty";

const items = [
  {
    title: "Drishya",
    location: "Digital Marketer, Freelancer",
    avatar: "/images/avatars/drizzy.png",
    rating: "4.9",
    src: "/images/content/host-pic-1.jpg",
    srcSet: "/images/content/host-pic-1@2x.jpg",
    url: "/host-profile",
    testimonial:
      "I was always skeptical about water \
    sports because I was scared of the water as I didn’t know how to swim.\
     That’s definitely changed now after giving surfing, and kayaking a try.\
      I just love being in the water knowing I’ll be safe with instructors \
      and the safety gear that’s provided.",
  },
  {
    title: "Anusha",
    location: "Make-up Artist, Freelancer",
    avatar: "/images/avatars/anusha.png",
    rating: "4.8",
    src: "/images/content/host-pic-2.jpg",
    srcSet: "/images/content/host-pic-2@2x.jpg",
    url: "/host-profile",
    testimonial:
      "It was very new! When something like this is easily accessible and affordable,people should take advantage of it and check it off their bucket list. ",
  },
  {
    title: "Shravan",
    location: "Product Manager, Glide Invest",
    avatar: "/images/avatars/shravan.png",
    rating: "5.0",
    src: "/images/content/host-pic-3.jpg",
    srcSet: "/images/content/host-pic-3@2x.jpg",
    url: "/host-profile",
    testimonial:
      "I didn’t know much about swimming or watersports. It was really a great experience. I would suggest everyone to give it a try. Sportifier for life. ",
  },
];

const dateOptions = ["month", "day", "year"];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Hosts = ({ classSection }) => {
  const [date, setDate] = useState(dateOptions[0]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div
      className={cn("section", classSection, styles.section)}
      id="testimonials"
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.inner}>
          <div className={styles.head}>
            <h2 className={cn("h2", styles.title)}>
              Loved by our sportifiers! 😍
            </h2>
            {/* <DropdownEmpty
              className={styles.dropdown}
              value={date}
              setValue={setDate}
              options={dateOptions}
            /> */}
          </div>
          <div className={styles.wrapper}>
            <Slider className="hosts-slider" {...settings}>
              {items.map((x, index) => (
                <Item className={styles.item} item={x} key={index} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hosts;
