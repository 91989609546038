import React from "react";
import styles from "./ReturnPolicy.module.sass";
import Main from "./Main";

const ReturnPolicy = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default ReturnPolicy;
