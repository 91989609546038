export const catalogList = [
  {
    title: "Leisure",
    icon: "coin",
    items: [
      {
        title: "Surfing Course",
        location: "Chennai Harbour",
        category: "Learn",
        priceOld: "₹ 20,000",
        priceActual: "₹ 14,000",
        date: "11 Sep 2022",
        rating: "5.0",
        image: "/images/content/event-sailing-course.jpg",
        image2x: "/images/content/event-sailing-course.jpg",
        slug: "surfing",
      },
      {
        title: "Watersport Event",
        location: "Kovalam, Chennai",
        category: "Leisure",
        priceOld: "₹ 20,000",
        priceActual: "₹ 14,000",
        date: "26 Sep 2022",
        rating: "4.8",
        image: "/images/content/event-watersport-1.jpg",
        image2x: "/images/content/event-watersport-1.jpg",
        slug: "kayaking",
      },
      {
        title: "Surf & Chill",
        location: "Kovalam, Chennai",
        category: "Leisure",
        priceOld: "₹ 20,000",
        priceActual: "₹ 14,000",
        date: "28 Sep 2022",
        rating: "4.9",
        image: "/images/content/event-watersports-2.jpg",
        image2x: "/images/content/event-watersports-2.jpg",
        slug: "standup_paddling",
      },
    ],
  },
];

export const eventData = [
  {
    title: "Leisure",
    icon: "coin",
    items: [
      {
        title: "Sailing course",
        slug: "sailing-course",
        slug: "/sailing-course",
        priceOld: "₹ 20,000",
        priceActual: "₹ 14,000",
        date: "Tue, Jul 20 - Fri, Jul 23",
        image: "/images/content/event-sailing-course.jpg",
        image2x: "/images/content/event-sailing-course.jpg",
        location: "Chennai harbour",
        detail: {
          event_brief: "Sailing course for Adults",
          event_desc:
            "Learn the absolute basics of sailing and by the end of the course, you will be able to steer a boat from point A to B all on your own!",
          gallery: [
            "/images/content/photo-1.1.jpg",
            "/images/content/photo-1.2.jpg",
            "/images/content/photo-1.3.jpg",
            "/images/content/photo-1.4.jpg",
          ],
          venue: {
            verbose: "",
            coordinates: ["", ""],
            location_verbose: "Chennai harbour",
          },
          batches: {
            no: 2,
            verbose: "4-day course on two consecutive weekends",
            no_days: 4,
            cost_per_head: "INR 14K",
            batch_meta: [
              {
                order: 1,
                date_verbose: "May 28th, and 29th - 7 to 10AM ",
                registration_end: "May 25th",
              },
              {
                order: 2,
                date_verbose: "June 4th, and 5th - 7 to 10AM",
                registration_end: "June 2nd",
              },
            ],
          },
          prerequisites: [
            "Knowledge of swimming is not required - we provide lifejackets and these are mandatory at all times",
            "Prior sailing experience is not required - the camp is made for first timers, and we have professional trainers.",
            "You don’t need to be super fit - as long as you are relatively healthy and are confident, you can get started",
          ],
          why_sign_up: [
            "To learn a new skill",
            "To get a certificate",
            "To meet new people",
            "Your idea of fun is trying something new",
          ],
          whats_included: [],
          what_to_carry: [],
          testimonials: [
            {
              verbose: "",
              avatar: "",
              name: "",
            },
            {
              verbose: "",
              avatar: "",
              name: "",
            },
            {
              verbose: "",
              avatar: "",
              name: "",
            },
          ],
        },
        tags: [],
        rating: 3.6,
      },
    ],
  },
];
