import React, { useState } from "react";
import cn from "classnames";
import styles from "./About.module.sass";

const Faq = () => {
  return (
    <div className={cn("section", styles.section)} id="about">
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <h2 className={cn("h2", styles.title)}>About Us!</h2>
        </div>
        <div className={styles.list}>
          <p>
            Our story started in 2019 and after serving over 500 customers,
            hosting events, and doing team outings through a rough couple of
            years (pandemic😔) across a couple of water sports, we believe this
            is the perfect time to scale and build a community around ‘offbeat
            sports’ by building a marketplace.Here’s a quick look at our journey
          </p>
          <br />
          <ul>
            <li>
              <b>Nov 2019:</b> Hosted a moonlight sailing event for close
              friends and went on to fill up sailing slots for every other
              weekend p0ost that ⛵
            </li>
            <li>
              <b>Dec 2019:</b> Taught newcomers sailing and helped them compete,
              and finish 3rd in a fun regatta. Apr 2020: Registered ourselves as
              a business. COVID had other plans LOL so we took a nice break!😴
            </li>
            <li>
              <b>Dec 2020:</b> Back in business Apr 2021: While sailing
              activities continued, we also started hosting surfing, and
              kayaking experiences with our partners 🏄
            </li>
            <li>
              <b>Sep 2021:</b> Started curating team outings for larger groups
              including corporates 🤑
            </li>
            <li>
              <b>May 2022:</b> Working towards building a marketplace for
              offbeat experiences down south both ‘Leisure and Learning based’
              🚀
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Faq;
