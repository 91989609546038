const surfing = {
  sport: "Surfing",
  image: "/images/gallery/surfing/hero.jpg",
  count: 7,
  vendors: [
    "surf-turf",
    "ocean-delight",
    "bay-of-life",
    "kallialay",
    "mother-ocean",
    "mumu",
    "guru",
  ],
  "surf-turf": {
    title: "Surf Turf",
    slug: "surf-turf",
    tags: ["Learn", "Leisure", "Watersports", "SurfTurf", "Chennai", "Surfing"],
    inclusion: [
      "Surfboard",
      "Professional instructors",
      "Beginner-friendly shallow beaches",
      "Safety gear.",
    ],
    content:
      "Surf Turf surf school, one of the biggest surf schools, is a great place to meet new people and make new friends while you discover the exciting world of surfing. Located on the golden beaches of Kovalam, Surf Turf is all about exploring the world of adventure water sports with safety being the first priority.",
    agenda:
      "The duration of each lesson is 1.5 hours. The first few minutes are all about learning the safety measures and standing instructions. You will also learn the land drill and how to stand on the surfboard. Next, you'll move into the shallow waters and learn how to paddle and stand before you actually surf the waves. The timings are from 8 am to 11 am and 3 pm to 6 pm. ",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Board Rentals and Repairs.",
    prerequisites: [
      "You do not need to know how to swim to try out surfing.",
      "Fitness levels are not a prerequisite, anybody can surf.",
      "There is no age/gender cap in surfing, anyone can surf.",
      "You do not need to have prior experience to try out surfing.",
    ],
    testimonials: [
      {
        user: "Clinton",
        rating: 4.3,
        review:
          "My 15-year-old daughter took her first surfing lessons this summer and she enjoyed every moment of it! She made new friends too. The teachers at Murthy Surf School were really patient and well-prepared. Highly recommended!",
      },
      {
        user: "Pramod",
        rating: 4.0,
        review:
          "My wife and I went on our first vacation to Kovalam beach. We had heard a lot about the water sports there and we wanted to explore that opportunity. Murthy Surf School was our first experience with water sports. We took surfing lessons and enjoyed every bit of it. We definitely recommend Murthy Surfing School to learn surfing in India",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "An extra pair of clothes",
      "Slippers",
      "A towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "Do I need to be a good swimmer?",
        content:
          "No, but it certainly helps. You can try out surfing without the knowledge of swimming!",
      },
      {
        title: "Is there any age limit to try out surfing?",
        content: "No, there is no age/gender",
      },
      {
        title: "Do we need to bring our surfing equipment?",
        content:
          " No, the surfing school will provide you with that. You just need to carry what is mentioned above.",
      },
      {
        title: "Will I be able to stand on the surfboard?",
        content:
          "With the help of certified professional trainers, you can catch your waves and stand on the board.",
      },
      {
        title: "Is surfing a dangerous sport?",
        content:
          "Your safety is our priority. You will be safe if you follow the guidance given by our professional trainers.",
      },
    ],
    prices: {
      learn: { "5 Day Course": "₹10,500", "1 Day Crash": "₹1,500" },
      leisure: { "Leisure Group": "₹4,500", "Go All out": "₹15,000" },
      rent: { "Rent Board": "5,000" },
    },
    rating: "4.8",
    reviews: "12",
    // cost: "₹14,0000 total",
    src: "/images/gallery/surfing/surf-turf/1.jpg",
    srcSet: "/images/content/card-pic-1@2x.jpg",
    gallery: [],
    options: [
      {
        title: "Chennai",
        icon: "location",
      },
    ],
  },
  "ocean-delight": {
    title: "Ocean Delight",
    slug: "ocean-delight",
    // priceOld: "₹20K",
    // priceActual: "₹14K",
    // categoryText: "LEARN",
    tags: ["Learn", "Leisure", "Watersports", "SurfTurf", "Chennai", "Surfing"],
    inclusion: [
      "Surfboard",
      "Professional instructors",
      "Beginner-friendly shallow beaches",
      "Safety gear.",
    ],
    content:
      "Surf Turf surf school, one of the biggest surf schools, is a great place to meet new people and make new friends while you discover the exciting world of surfing. Located on the golden beaches of Kovalam, Surf Turf is all about exploring the world of adventure water sports with safety being the first priority.",
    agenda:
      "The duration of each lesson is 1.5 hours. The first few minutes are all about learning the safety measures and standing instructions. You will also learn the land drill and how to stand on the surfboard. Next, you'll move into the shallow waters and learn how to paddle and stand before you actually surf the waves. The timings are from 8 am to 11 am and 3 pm to 6 pm. ",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Board Rentals and Repairs.",
    prerequisites: [
      "You do not need to know how to swim to try out surfing.",
      "Fitness levels are not a prerequisite, anybody can surf.",
      "There is no age/gender cap in surfing, anyone can surf.",
      "You do not need to have prior experience to try out surfing.",
    ],
    testimonials: [
      {
        user: "Clinton",
        rating: 4.3,
        review:
          "My 15-year-old daughter took her first surfing lessons this summer and she enjoyed every moment of it! She made new friends too. The teachers at Murthy Surf School were really patient and well-prepared. Highly recommended!",
      },
      {
        user: "Pramod",
        rating: 4.0,
        review:
          "My wife and I went on our first vacation to Kovalam beach. We had heard a lot about the water sports there and we wanted to explore that opportunity. Murthy Surf School was our first experience with water sports. We took surfing lessons and enjoyed every bit of it. We definitely recommend Murthy Surfing School to learn surfing in India",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "An extra pair of clothes",
      "Slippers",
      "A towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "Do I need to be a good swimmer?",
        content:
          "No, but it certainly helps. You can try out surfing without the knowledge of swimming!",
      },
      {
        title: "Is there any age limit to try out surfing?",
        content: "No, there is no age/gender",
      },
      {
        title: "Do we need to bring our surfing equipment?",
        content:
          " No, the surfing school will provide you with that. You just need to carry what is mentioned above.",
      },
      {
        title: "Will I be able to stand on the surfboard?",
        content:
          "With the help of certified professional trainers, you can catch your waves and stand on the board.",
      },
      {
        title: "Is surfing a dangerous sport?",
        content:
          "Your safety is our priority. You will be safe if you follow the guidance given by our professional trainers.",
      },
    ],
    rating: "4.9",
    reviews: "24",
    // cost: "₹14,000 total",
    src: "/images/gallery/surfing/ocean-delight/1.jpg",
    srcSet: "/images/content/card-pic-2@2x.jpg",
    prices: {
      learn: { "5 Day Course": "₹10,500", "1 Day Crash": "₹1,500" },
      leisure: { "Leisure Group": "₹4,500", "Go All out": "₹15,000" },
      rent: { "Rent Board": "5,000" },
    },
    options: [
      {
        title: "Chennai",
        icon: "location",
      },
    ],
  },
  "bay-of-life": {
    title: "Bay Of Life",
    slug: "bay-of-life",
    // priceOld: "₹20K",
    // priceActual: "₹14K",
    // categoryText: "superparter",
    tags: ["Learn", "Leisure", "Watersports", "SurfTurf", "Chennai", "Surfing"],
    inclusion: [
      "Surfboard",
      "Professional instructors",
      "Beginner-friendly shallow beaches",
      "Safety gear.",
    ],
    content:
      "Surf Turf surf school, one of the biggest surf schools, is a great place to meet new people and make new friends while you discover the exciting world of surfing. Located on the golden beaches of Kovalam, Surf Turf is all about exploring the world of adventure water sports with safety being the first priority.",
    agenda:
      "The duration of each lesson is 1.5 hours. The first few minutes are all about learning the safety measures and standing instructions. You will also learn the land drill and how to stand on the surfboard. Next, you'll move into the shallow waters and learn how to paddle and stand before you actually surf the waves. The timings are from 8 am to 11 am and 3 pm to 6 pm. ",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Board Rentals and Repairs.",
    prerequisites: [
      "You do not need to know how to swim to try out surfing.",
      "Fitness levels are not a prerequisite, anybody can surf.",
      "There is no age/gender cap in surfing, anyone can surf.",
      "You do not need to have prior experience to try out surfing.",
    ],
    testimonials: [
      {
        user: "Clinton",
        rating: 4.3,
        review:
          "My 15-year-old daughter took her first surfing lessons this summer and she enjoyed every moment of it! She made new friends too. The teachers at Murthy Surf School were really patient and well-prepared. Highly recommended!",
      },
      {
        user: "Pramod",
        rating: 4.0,
        review:
          "My wife and I went on our first vacation to Kovalam beach. We had heard a lot about the water sports there and we wanted to explore that opportunity. Murthy Surf School was our first experience with water sports. We took surfing lessons and enjoyed every bit of it. We definitely recommend Murthy Surfing School to learn surfing in India",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "An extra pair of clothes",
      "Slippers",
      "A towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "Do I need to be a good swimmer?",
        content:
          "No, but it certainly helps. You can try out surfing without the knowledge of swimming!",
      },
      {
        title: "Is there any age limit to try out surfing?",
        content: "No, there is no age/gender",
      },
      {
        title: "Do we need to bring our surfing equipment?",
        content:
          " No, the surfing school will provide you with that. You just need to carry what is mentioned above.",
      },
      {
        title: "Will I be able to stand on the surfboard?",
        content:
          "With the help of certified professional trainers, you can catch your waves and stand on the board.",
      },
      {
        title: "Is surfing a dangerous sport?",
        content:
          "Your safety is our priority. You will be safe if you follow the guidance given by our professional trainers.",
      },
    ],
    rating: "4.8",
    reviews: "102",
    // cost: "₹14,000 total",
    src: "/images/gallery/surfing/bay-of-life/1.jpg",
    srcSet: "/images/content/card-pic-3@2x.jpg",
    prices: {
      learn: { "5 Day Course": "₹10,500", "1 Day Crash": "₹1,500" },
      leisure: { "Leisure Group": "₹4,500", "Go All out": "₹15,000" },
      rent: { "Rent Board": "5,000" },
    },
    options: [
      {
        title: "Chennai",
        icon: "location",
      },
    ],
  },
  kallialay: {
    title: "Kallialay",
    slug: "kallialay",
    src: "/images/gallery/surfing/kallialay/1.jpg",
    rating: "4.5",
    reviews: "5",
    tags: ["Learn", "Leisure", "Watersports", "SurfTurf", "Chennai", "Surfing"],
    inclusion: [
      "Surfboard",
      "Professional instructors",
      "Beginner-friendly shallow beaches",
      "Safety gear.",
    ],
    content:
      "Surf Turf surf school, one of the biggest surf schools, is a great place to meet new people and make new friends while you discover the exciting world of surfing. Located on the golden beaches of Kovalam, Surf Turf is all about exploring the world of adventure water sports with safety being the first priority.",
    agenda:
      "The duration of each lesson is 1.5 hours. The first few minutes are all about learning the safety measures and standing instructions. You will also learn the land drill and how to stand on the surfboard. Next, you'll move into the shallow waters and learn how to paddle and stand before you actually surf the waves. The timings are from 8 am to 11 am and 3 pm to 6 pm. ",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Board Rentals and Repairs.",
    prerequisites: [
      "You do not need to know how to swim to try out surfing.",
      "Fitness levels are not a prerequisite, anybody can surf.",
      "There is no age/gender cap in surfing, anyone can surf.",
      "You do not need to have prior experience to try out surfing.",
    ],
    testimonials: [
      {
        user: "Clinton",
        rating: 4.3,
        review:
          "My 15-year-old daughter took her first surfing lessons this summer and she enjoyed every moment of it! She made new friends too. The teachers at Murthy Surf School were really patient and well-prepared. Highly recommended!",
      },
      {
        user: "Pramod",
        rating: 4.0,
        review:
          "My wife and I went on our first vacation to Kovalam beach. We had heard a lot about the water sports there and we wanted to explore that opportunity. Murthy Surf School was our first experience with water sports. We took surfing lessons and enjoyed every bit of it. We definitely recommend Murthy Surfing School to learn surfing in India",
      },
    ],
    prices: {
      learn: { "5 Day Course": "₹10,500", "1 Day Crash": "₹1,500" },
      leisure: { "Leisure Group": "₹4,500", "Go All out": "₹15,000" },
      rent: { "Rent Board": "5,000" },
    },
    what_to_carry: [
      "Athleisure wear",
      "An extra pair of clothes",
      "Slippers",
      "A towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    prices: {
      learn: { "5 Day Course": "₹10,500", "1 Day Crash": "₹1,500" },
      leisure: { "Leisure Group": "₹4,500", "Go All out": "₹15,000" },
      rent: { "Rent Board": "5,000" },
    },
    options: [
      {
        title: "Chennai",
        icon: "location",
      },
    ],
    faq: [
      {
        title: "Do I need to be a good swimmer?",
        content:
          "No, but it certainly helps. You can try out surfing without the knowledge of swimming!",
      },
      {
        title: "Is there any age limit to try out surfing?",
        content: "No, there is no age/gender",
      },
      {
        title: "Do we need to bring our surfing equipment?",
        content:
          " No, the surfing school will provide you with that. You just need to carry what is mentioned above.",
      },
      {
        title: "Will I be able to stand on the surfboard?",
        content:
          "With the help of certified professional trainers, you can catch your waves and stand on the board.",
      },
      {
        title: "Is surfing a dangerous sport?",
        content:
          "Your safety is our priority. You will be safe if you follow the guidance given by our professional trainers.",
      },
    ],
  },
  "mother-ocean": {
    title: "Mother ocean",
    slug: "mother-ocean",
    rating: "4.0",
    reviews: "9",
    src: "/images/gallery/surfing/mother-ocean/1.jpg",
    tags: ["Learn", "Leisure", "Watersports", "SurfTurf", "Chennai", "Surfing"],
    inclusion: [
      "Surfboard",
      "Professional instructors",
      "Beginner-friendly shallow beaches",
      "Safety gear.",
    ],
    content:
      "Surf Turf surf school, one of the biggest surf schools, is a great place to meet new people and make new friends while you discover the exciting world of surfing. Located on the golden beaches of Kovalam, Surf Turf is all about exploring the world of adventure water sports with safety being the first priority.",
    agenda:
      "The duration of each lesson is 1.5 hours. The first few minutes are all about learning the safety measures and standing instructions. You will also learn the land drill and how to stand on the surfboard. Next, you'll move into the shallow waters and learn how to paddle and stand before you actually surf the waves. The timings are from 8 am to 11 am and 3 pm to 6 pm. ",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Board Rentals and Repairs.",
    prerequisites: [
      "You do not need to know how to swim to try out surfing.",
      "Fitness levels are not a prerequisite, anybody can surf.",
      "There is no age/gender cap in surfing, anyone can surf.",
      "You do not need to have prior experience to try out surfing.",
    ],
    testimonials: [
      {
        user: "Clinton",
        rating: 4.3,
        review:
          "My 15-year-old daughter took her first surfing lessons this summer and she enjoyed every moment of it! She made new friends too. The teachers at Murthy Surf School were really patient and well-prepared. Highly recommended!",
      },
      {
        user: "Pramod",
        rating: 4.0,
        review:
          "My wife and I went on our first vacation to Kovalam beach. We had heard a lot about the water sports there and we wanted to explore that opportunity. Murthy Surf School was our first experience with water sports. We took surfing lessons and enjoyed every bit of it. We definitely recommend Murthy Surfing School to learn surfing in India",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "An extra pair of clothes",
      "Slippers",
      "A towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    prices: {
      learn: { "5 Day Course": "₹10,500", "1 Day Crash": "₹1,500" },
      leisure: { "Leisure Group": "₹4,500", "Go All out": "₹15,000" },
      rent: { "Rent Board": "5,000" },
    },
    options: [
      {
        title: "Chennai",
        icon: "location",
      },
    ],
    faq: [
      {
        title: "Do I need to be a good swimmer?",
        content:
          "No, but it certainly helps. You can try out surfing without the knowledge of swimming!",
      },
      {
        title: "Is there any age limit to try out surfing?",
        content: "No, there is no age/gender",
      },
      {
        title: "Do we need to bring our surfing equipment?",
        content:
          " No, the surfing school will provide you with that. You just need to carry what is mentioned above.",
      },
      {
        title: "Will I be able to stand on the surfboard?",
        content:
          "With the help of certified professional trainers, you can catch your waves and stand on the board.",
      },
      {
        title: "Is surfing a dangerous sport?",
        content:
          "Your safety is our priority. You will be safe if you follow the guidance given by our professional trainers.",
      },
    ],
  },
  mumu: {
    title: "Mumu",
    slug: "mumu",
    rating: "4.2",
    reviews: "10",
    src: "/images/gallery/surfing/mumu/1.jpg",
    tags: ["Learn", "Leisure", "Watersports", "SurfTurf", "Chennai", "Surfing"],
    inclusion: [
      "Surfboard",
      "Professional instructors",
      "Beginner-friendly shallow beaches",
      "Safety gear.",
    ],
    content:
      "Surf Turf surf school, one of the biggest surf schools, is a great place to meet new people and make new friends while you discover the exciting world of surfing. Located on the golden beaches of Kovalam, Surf Turf is all about exploring the world of adventure water sports with safety being the first priority.",
    agenda:
      "The duration of each lesson is 1.5 hours. The first few minutes are all about learning the safety measures and standing instructions. You will also learn the land drill and how to stand on the surfboard. Next, you'll move into the shallow waters and learn how to paddle and stand before you actually surf the waves. The timings are from 8 am to 11 am and 3 pm to 6 pm. ",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Board Rentals and Repairs.",
    prerequisites: [
      "You do not need to know how to swim to try out surfing.",
      "Fitness levels are not a prerequisite, anybody can surf.",
      "There is no age/gender cap in surfing, anyone can surf.",
      "You do not need to have prior experience to try out surfing.",
    ],
    testimonials: [
      {
        user: "Clinton",
        rating: 4.3,
        review:
          "My 15-year-old daughter took her first surfing lessons this summer and she enjoyed every moment of it! She made new friends too. The teachers at Murthy Surf School were really patient and well-prepared. Highly recommended!",
      },
      {
        user: "Pramod",
        rating: 4.0,
        review:
          "My wife and I went on our first vacation to Kovalam beach. We had heard a lot about the water sports there and we wanted to explore that opportunity. Murthy Surf School was our first experience with water sports. We took surfing lessons and enjoyed every bit of it. We definitely recommend Murthy Surfing School to learn surfing in India",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "An extra pair of clothes",
      "Slippers",
      "A towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    prices: {
      learn: { "5 Day Course": "₹10,500", "1 Day Crash": "₹1,500" },
      leisure: { "Leisure Group": "₹4,500", "Go All out": "₹15,000" },
      rent: { "Rent Board": "5,000" },
    },
    options: [
      {
        title: "Chennai",
        icon: "location",
      },
    ],
    faq: [
      {
        title: "Do I need to be a good swimmer?",
        content:
          "No, but it certainly helps. You can try out surfing without the knowledge of swimming!",
      },
      {
        title: "Is there any age limit to try out surfing?",
        content: "No, there is no age/gender",
      },
      {
        title: "Do we need to bring our surfing equipment?",
        content:
          " No, the surfing school will provide you with that. You just need to carry what is mentioned above.",
      },
      {
        title: "Will I be able to stand on the surfboard?",
        content:
          "With the help of certified professional trainers, you can catch your waves and stand on the board.",
      },
      {
        title: "Is surfing a dangerous sport?",
        content:
          "Your safety is our priority. You will be safe if you follow the guidance given by our professional trainers.",
      },
    ],
  },
  guru: {
    title: "Guru",
    slug: "guru",
    rating: "4.4",
    reviews: "15",
    src: "/images/gallery/surfing/guru/1.png",
    tags: ["Learn", "Leisure", "Watersports", "SurfTurf", "Chennai", "Surfing"],
    inclusion: [
      "Surfboard",
      "Professional instructors",
      "Beginner-friendly shallow beaches",
      "Safety gear.",
    ],
    content:
      "Surf Turf surf school, one of the biggest surf schools, is a great place to meet new people and make new friends while you discover the exciting world of surfing. Located on the golden beaches of Kovalam, Surf Turf is all about exploring the world of adventure water sports with safety being the first priority.",
    agenda:
      "The duration of each lesson is 1.5 hours. The first few minutes are all about learning the safety measures and standing instructions. You will also learn the land drill and how to stand on the surfboard. Next, you'll move into the shallow waters and learn how to paddle and stand before you actually surf the waves. The timings are from 8 am to 11 am and 3 pm to 6 pm. ",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Board Rentals and Repairs.",
    prerequisites: [
      "You do not need to know how to swim to try out surfing.",
      "Fitness levels are not a prerequisite, anybody can surf.",
      "There is no age/gender cap in surfing, anyone can surf.",
      "You do not need to have prior experience to try out surfing.",
    ],
    testimonials: [
      {
        user: "Clinton",
        rating: 4.3,
        review:
          "My 15-year-old daughter took her first surfing lessons this summer and she enjoyed every moment of it! She made new friends too. The teachers at Murthy Surf School were really patient and well-prepared. Highly recommended!",
      },
      {
        user: "Pramod",
        rating: 4.0,
        review:
          "My wife and I went on our first vacation to Kovalam beach. We had heard a lot about the water sports there and we wanted to explore that opportunity. Murthy Surf School was our first experience with water sports. We took surfing lessons and enjoyed every bit of it. We definitely recommend Murthy Surfing School to learn surfing in India",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "An extra pair of clothes",
      "Slippers",
      "A towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    prices: {
      learn: { "5 Day Course": "₹10,500", "1 Day Crash": "₹1,500" },
      leisure: { "Leisure Group": "₹4,500", "Go All out": "₹15,000" },
      rent: { "Rent Board": "5,000" },
    },
    options: [
      {
        title: "Chennai",
        icon: "location",
      },
    ],
    faq: [
      {
        title: "Do I need to be a good swimmer?",
        content:
          "No, but it certainly helps. You can try out surfing without the knowledge of swimming!",
      },
      {
        title: "Is there any age limit to try out surfing?",
        content: "No, there is no age/gender",
      },
      {
        title: "Do we need to bring our surfing equipment?",
        content:
          " No, the surfing school will provide you with that. You just need to carry what is mentioned above.",
      },
      {
        title: "Will I be able to stand on the surfboard?",
        content:
          "With the help of certified professional trainers, you can catch your waves and stand on the board.",
      },
      {
        title: "Is surfing a dangerous sport?",
        content:
          "Your safety is our priority. You will be safe if you follow the guidance given by our professional trainers.",
      },
    ],
  },
};

const standup_paddling = {
  sport: "Standup Paddling",
  image: "/images/gallery/standup_paddling/hero.jpg",
  count: 7,
  vendors: [
    "bay-of-life",
    "surf-turf",
    "aquaoutback",
    "quest-adventure-sports-academy",
    "temple-adventure",
    "urban-nomads",
    "samudra",
  ],
  "bay-of-life": {
    title: "Bay Of Life",
    slug: "bay-of-life",
    tags: ["Learn", "Leisure", "Watersports"],
    inclusion: ["Surfboard", "Paddles", "Safety Gear", "Trained professionals"],
    content:
      "The first kayaking school, Bay or Life boasts of Professional Instructors certified by national and international water sports bodies. You are in safe hands. Trusted Knowledge of over 9 years. Child-friendly shallow beach - a safe haven for beginners and non-swimmers too. Count on Bay Of Life to have the perfect standup paddling experience!",
    agenda:
      "Intro SUP Lesson - Learn all about standup paddling from scratch, duration 1.5 hours| Beginner friendly\
    SUP Ocean 5 day course - Explore SUP, start from scratch and get familiar with the basics of SUP\
    SUP Pro 10 Day Course - Explore SUP, start from scratch and become a pro at SUP",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Board Rentals and Repairs.",
    prerequisites: [
      "You do not need to have swimming knowledge to try out stand-up paddling.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in stand-up paddling",
      "You do not need to have prior experience to try out stand-up paddling.",
      "Fitness levels are not a prerequisite, anyone can try stand-up paddling.",
    ],
    testimonials: [
      {
        user: "Antony Hyson, 21, Videographer",
        rating: 4.3,
        review:
          "I always thought that standup paddling is a very difficult thing to do. On a whim, I tried out a beginner's lesson in Standup paddling and realised that it is not so difficult after all! It was very safe and enjoyable.",
      },
      {
        user: "Chitresh T, 22, Student ",
        rating: 4.0,
        review:
          "Standup paddling was my first step into the world of adventure watersports and now I can surf too. The coach was very patient. I felt very comfortable as the safety measures were in place.",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "An extra pair of clothes",
      "Slippers",
      "Towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "What should I wear?",
        content:
          "Wear something you're comfortable in! Clothing that allows movement works best.",
      },
      {
        title: "Is stand up paddle boarding hard?",
        content: "SUP is one of the easiest water sport to participate in.",
      },
      {
        title: "is SUP easier than surfing?",
        content:
          "Yes, it requires less skill and energy as compared to surfing.",
      },
    ],
    prices: {
      leisure: { "Intro SUP lesson ": "INR 1800" },
      learn: {
        "Sup ocean 5 day course": "INR 8500",
        "Sup Pro 10 days course": "INR 17000",
      },
    },
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/standup_paddling/bay-of-life/1.jpg",
    // srcSet: "/images/content/card-pic-1@2x.jpg",
    gallery: [],
    location:
      "9/9, Muthumariamman Koil St, Kunnukadu, Kovalam, Tamil Nadu 603112",
    google_map:
      "https://www.google.co.in/maps/dir//bay+of+life/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3a525b122d86c629:0x19d9883c558fa118?sa=X&ved=2ahUKEwjTnvjayID6AhVaELcAHWGHASUQ9Rd6BAhiEAU",
  },
  "surf-turf": {
    title: "Surf Turf",
    slug: "surf-turf",
    tags: ["Learn", "Leisure", "Watersports"],
    inclusion: ["Surfboard", "Paddles", "Safety Gear", "Trained professionals"],
    content:
      "Surf Turf surf school, one of the biggest surf schools,  is a great place to meet new people and make new friends while you discover the exciting world of adventure water sports with standup paddling. Located in Kovalam, Surf Turf is a great place to hang out, try out standup paddling under the guidance of professional instructors and top notch safety gear.",
    agenda:
      "Intro SUP Lesson - A beginner friendly introductory lesson on SUP for a duration of 1.5 hours\
    Regular SUP Lesson - A regular 1.5 hour SUP session to brush up your basics and get going\
    Discover Lesson Kids - A specially curated 1.5 hour SUP lesson for kids\
    SUP Ocean 5 day course - Explore SUP, start from scratch and get familiar with the basics of SUP\
    SUP Pro 10 Day Course - Explore SUP, start from scratch and become a pro at SUP",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Board Rentals and Repairs.",
    prerequisites: [
      "You do not need to have swimming knowledge to try out stand-up paddling.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in stand-up paddling",
      "You do not need to have prior experience to try out stand-up paddling.",
      "Fitness levels are not a prerequisite, anyone can try stand-up paddling.",
    ],
    testimonials: [
      {
        user: "Miracle Joan, 20, Social worker",
        rating: 4.3,
        review:
          "Standup paddling was a thrilling experience for me. My connection with water was limited to beginners level swimming. Standup paddling was a game-changer for me. I enjoyed every minute of it.",
      },
      {
        user: "Anika Panwar, 30, Marketing Director",
        rating: 4.0,
        review:
          "Highly recommend Surf Turf for all things water sports, especially Standup Paddling. It was very safe and adventurous! I will go back there for my next vacay to explore more options!",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "Slippers",
      "Towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "What should I wear?",
        content:
          "Wear something you're comfortable in! Clothing that allows movement works best.",
      },
      {
        title: "Is stand up paddle boarding hard?",
        content: "SUP is one of the easiest water sport to participate in.",
      },
      {
        title: "is SUP easier than surfing?",
        content:
          "Yes, it requires less skill and energy as compared to surfing.",
      },
    ],
    prices: {
      leisure: {
        " Regular SUP Lesson  ": "INR 1250",
        "Discover Lesson Kids ": "INR 1250",
        "5 Days Course SUP": "INR 9100",
        "10 Days Course Sup ": "INR 16520",
      },
      learn: { "Intro SUP lesson ": "INR 1900" },
    },
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/standup_paddling/surf-turf/1.jpg",
    srcSet: "/images/content/card-pic-1@2x.jpg",
    gallery: [],
    location:
      "Plot No. 10, Pearl Beach, Ansari Nagar, Kovalam, Tamil Nadu 603112",
    google_map:
      "https://www.google.com/maps/place/Surf+Turf/@12.7862372,80.2543207,15z/data=!4m18!1m9!3m8!1s0x0:0xf931ff1ed08d6ac0!2sSurf+Turf!5m2!4m1!1i2!8m2!3d12.7862372!4d80.2543207!3m7!1s0x0:0xf931ff1ed08d6ac0!5m2!4m1!1i2!8m2!3d12.7862372!4d80.2543207",
  },
  aquaoutback: {
    title: "Aquaoutback",
    slug: "aquaoutback",
    tags: ["Learn", "Leisure", "Watersports"],
    inclusion: ["Surfboard", "Paddles", "Safety Gear", "Trained professionals"],
    content:
      "AquaOutback is all about exploring safe adventure water sports in the vast beaches of Toothukudi. You will not only make friends but also explore the waters together. Learn Standup paddling with world class safety in place.",
    agenda:
      "Intro SUP Lesson - Learn all about standup paddling from scratch, duration 1.5 hours| Beginner friendly\
    Regular SUP Lesson - Know the basics of SUP? Get going on your quest! ",
    classes:
      "Beginners, Intermediate, Advanced, Group lessons, Individual lessons. Flexible classes: 3 days to 3 months. Paddle Rentals and Repairs.",
    prerequisites: [
      "You do not need to have swimming knowledge to try out stand-up paddling.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in stand-up paddling",
      "You do not need to have prior experience to try out stand-up paddling.",
      "Fitness levels are not a prerequisite, anyone can try stand-up paddling.",
    ],
    testimonials: [
      {
        user: "Anita Srinivasan, 31, Product Manager",
        rating: 4.3,
        review:
          "I am a non-swimmer and didnt expect to make much progress in Standup Paddling. I surprised myself! My coach was very helpful and I was very comforatble with the safety measures in place.",
      },
      {
        user: "Jahnavi Aggarwal, 32, Freelancer",
        rating: 4.0,
        review:
          "I wanted to try out an offbeat experience and chose standup paddling. I love travelling solo and offbeat experiences mean that I dont meet many people. This was totally different! I not only learnt standup paddling, but also made a new group of friends! Thanks to Aqua Out Back for the wholesome experience!",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "Slippers",
      "Towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "What should I wear?",
        content:
          "Wear something you're comfortable in! Clothing that allows movement works best.",
      },
      {
        title: "Is stand up paddle boarding hard?",
        content: "SUP is one of the easiest water sport to participate in.",
      },
      {
        title: "is SUP easier than surfing?",
        content:
          "Yes, it requires less skill and energy as compared to surfing.",
      },
    ],
    prices: {
      leisure: {
        "Intro SUP Lesson ": "INR 2000",
        "Regular SUP session": "INR 2000",
      },
    },
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/standup_paddling/aquaoutback/1.jpg",
    srcSet: "/images/content/card-pic-1@2x.jpg",
    gallery: [],
    location: "105, 2nd St, Toovipuram, Thoothukudi, Tamil Nadu 628003",
    google_map:
      "https://www.google.co.in/maps/dir/28.4552514,77.5046096/aqua+outback/@18.487879,69.9137437,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b03ef953699c09d:0xb69f2e19d5bb10dc!2m2!1d78.133699!2d8.802991",
  },
  "quest-adventure-sports-academy": {
    title: "Quest Adventure Sports Academy",
    slug: "quest-adventure-sports-academy",
    tags: ["Learn", "Leisure", "Watersports", "Quest Academy", "Chennai"],
    inclusion: ["Surfboard", "Paddles", "Safety Gear", "Trained professionals"],
    content:
      "Explore the coasts of Rameswaram through Standup Paddling! Quest Adventure Sports Academy is all about safe adventure water sports. Learn Standup Paddling safely and make new friends on your quest for adventure!",
    agenda:
      "Intro SUP Lesson - Learn all about standup paddling from scratch, duration 1.5 hours| Beginner friendly\
    SUP Level 1 - \
    SUP Level 2 - \
    SUP Level 3 - \
    SUP Bootcamp - ",
    classes:
      "Beginners\
    Intermediate\
    Advanced\
    Group lessons\
    Individual lessons\
    Flexible classes:\
    3 days to 3 months\
    Paddle Rentals and Repairs",
    prerequisites: [
      "You do not need to have swimming knowledge to try out stand-up paddling.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in stand-up paddling",
      "You do not need to have prior experience to try out stand-up paddling.",
      "Fitness levels are not a prerequisite, anyone can try stand-up paddling.",
    ],
    testimonials: [
      {
        user: "Anusha Swamy, 31, Make up artist",
        rating: 4.3,
        review:
          "I always assumed balancing would be difficult. My coach at Quest Adventure Sports Academy busted that myth. It is actually easy if you get the technique right. I was very happy with my experience",
      },
      {
        user: "Maalvika Manoj, 28, Singer",
        rating: 4.0,
        review:
          "Standup Paddling was never something that I thought I would ever do. Being a non-swimmer, I was apprehensive at first. My coach was very considerate and I felt very safe. Learning Standup Paddling is truly an achievement for me.",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "Slippers",
      "Towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "What should I wear?",
        content:
          "Wear something you're comfortable in! Clothing that allows movement works best.",
      },
      {
        title: "Is stand up paddle boarding hard?",
        content: "SUP is one of the easiest water sport to participate in.",
      },
      {
        title: "is SUP easier than surfing?",
        content:
          "Yes, it requires less skill and energy as compared to surfing.",
      },
    ],
    prices: {
      leisure: { " Intro SUP Lesson ": "INR 1800" },
      learn: {
        "SUP Level 1 ": "INR 7500",
        "SUP level 2": "INR 12500",
        "SUP Level 3": " INR 17500",
        "SUP Bootcamp": "INR 21240",
      },
    },
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/standup_paddling/quest-adventure-sports-academy/1.jpg",
    srcSet: "/images/content/card-pic-1@2x.jpg",
    gallery: [],
    location:
      "1/1164, Beach Road, Pirapanvalasai Village, Ramanathapuram, Tamil Nadu 623516",
    google_map:
      "https://www.google.co.in/maps/dir//quest+academy+asia/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3b01eb9613b2e173:0x7e34b4ffdfa1b32?sa=X&ved=2ahUKEwjNlprBzIn6AhW-T2wGHdtRAFcQ9Rd6BAhPEAU",
  },
  "temple-adventure": {
    title: "Temple Adventure",
    slug: "temple-adventure",
    tags: ["Learn", "Leisure", "Watersports", "Quest Academy", "Chennai"],
    inclusion: ["Surfboard", "Paddles", "Safety Gear", "Trained professionals"],
    content:
      "Temple Adventure, located at Pondicherry, is one of the best places to hang out for water based activities, especially Standup Paddling. Explore the soothing waters and make amazing memories at this beautiful, scenic locale. If you are in Pondicherry, make sure you don't miss this!",
    agenda:
      "A beginners lesson on Standup Paddling is all about getting the basics right - technique, balance and rhythm. First you will be instroduced to the theoretical aspects of Standup Paddling. Next, you will learn how to balance and paddle in the shallow waters before you move into the coastal waters under supervision. Each lesson is for a duration of 1..5 hours.",
    classes:
      "Beginners\
    Intermediate\
    Advanced\
    Group lessons\
    Individual lessons\
    Flexible classes:\
    3 days to 3 months\
    Paddle Rentals and Repairs",
    prerequisites: [
      "You do not need to have swimming knowledge to try out stand-up paddling.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in stand-up paddling",
      "You do not need to have prior experience to try out stand-up paddling.",
      "Fitness levels are not a prerequisite, anyone can try stand-up paddling.",
    ],
    testimonials: [
      {
        user: "Priyadarshini, 24, Freelance",
        rating: 4.3,
        review:
          "Standup Paddling looked challenging, but I realised it wasvery different in reality. My instructor told me that Standup Paddling is easy if you get the technique and balancing factor right. I experienced it myself when I tried Standup Paddling in the coastal waters. Had lots of fun and the food was great!",
      },
      {
        user: "Bhisham Sahu, 35, Founder",
        rating: 4.0,
        review:
          "My first experience with adventure water sports was at Temple Adventure. Even for a beginner, I knew that I was in safe hands. My instructor explained all the safety instructions and patiently answered my questions. I had lots of fun in the water. 10/10 for my amazing experience.",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "Slippers",
      "Towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "What should I wear?",
        content:
          "Wear something you're comfortable in! Clothing that allows movement works best.",
      },
      {
        title: "Is stand up paddle boarding hard?",
        content: "SUP is one of the easiest water sport to participate in.",
      },
      {
        title: "is SUP easier than surfing?",
        content:
          "Yes, it requires less skill and energy as compared to surfing.",
      },
    ],
    prices: null,
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/standup_paddling/temple-adventure/1.jpg",
    srcSet: "/images/content/card-pic-1@2x.jpg",
    gallery: [],
    location:
      "9 Archbishop Gandhi Street 1st Road Opposite Indira Gandhi, Colas Nagar, Puducherry, 605001",
    google_map:
      "https://www.google.co.in/maps/dir//temple+adventure+pondicherry/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3a53618654000001:0xe0fe344c4b5325cc?sa=X&ved=2ahUKEwizz_rMzIn6AhVRS2wGHahxBHUQ9Rd6BAhnEAU",
  },
  "urban-nomads": {
    title: "Urban nomads",
    slug: "urban-nomads",
    tags: ["Learn", "Leisure", "Watersports", "Quest Academy", "Chennai"],
    inclusion: ["Surfboard", "Paddles", "Safety Gear", "Trained professionals"],
    content:
      "Urban Nomads is an Adventure Water Sports School located along the Promenade beach of Pondicherry. This is a great place to hang out if you want to try out Standup Paddling with top notch safety. Professional instructors, top notch safety gear and pristine waters make this one of the best places to Kayak in India.",
    agenda:
      "Standup Paddling lessons are 1.5 hours of safe fun and learning experience. You will learn all about the basics of Standup Paddling first and move on to the land drills. Then you'll be taught to balance, stand up and paddle in the shllow waves close to the beach. The instructors will help you clear your doubts and you can have a thorough standup paddling lesson. The timings are from 8 am to 11 am and 3 pm to 6 pm. ",
    classes:
      "Beginners\
    Intermediate\
    Advanced\
    Group lessons\
    Individual lessons\
    Flexible classes:\
    3 days to 3 months\
    Paddle Rentals and Repairs",
    prerequisites: [
      "You do not need to have swimming knowledge to try out stand-up paddling.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in stand-up paddling",
      "You do not need to have prior experience to try out stand-up paddling.",
      "Fitness levels are not a prerequisite, anyone can try stand-up paddling.",
    ],
    testimonials: [
      {
        user: "Pallavi Shanbhag, 29, Program Coordinator",
        rating: 4.3,
        review:
          " My friends and I travelled to Promenade Beach in Pondicherry for a long weekend. We saw interesting watersports happening there and instantly signed up. We had a great time learning Standup Paddling. The instructors were very professional and knew how to teach total beginners like us.",
      },
      {
        user: "Arvind Iyer, 25, Product Manager",
        rating: 4.0,
        review:
          "I had learnt kayaking at Urban Nomads last year. I wanted to try something new this time and came back to explore Standup paddling. The instructors were superb as usual. The food and ambience were outstanding. Thank you Urban Nomads! Expect me next year again!",
      },
    ],
    what_to_carry: [
      "Athleisure wear",
      "Slippers",
      "Towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "What should I wear?",
        content:
          "Wear something you're comfortable in! Clothing that allows movement works best.",
      },
      {
        title: "Is stand up paddle boarding hard?",
        content: "SUP is one of the easiest water sport to participate in.",
      },
      {
        title: "is SUP easier than surfing?",
        content:
          "Yes, it requires less skill and energy as compared to surfing.",
      },
    ],
    prices: null,
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/standup_paddling/urban-nomads/1.jpg",
    srcSet: "/images/content/card-pic-1@2x.jpg",
    gallery: [],
    location: "3, St Theresa St, MG Road Area, Puducherry, 605001",
    google_map:
      "https://www.google.co.in/maps/dir//urban+nomads+pondicherry/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3a5363324bbf668f:0xf6ee374827931851?sa=X&ved=2ahUKEwjlq-XZzIn6AhX5ZWwGHUDNCzQQ9Rd6BAhpEAU",
  },
  samudra: {
    title: "Samudra",
    slug: "samudra",
    tags: ["Learn", "Leisure", "Watersports", "Quest Academy", "Chennai"],
    inclusion: ["Surfboard", "Paddles", "Safety Gear", "Trained professionals"],
    content:
      "Samudra Adventures located at Pondicherry is actively involved in promoting adventure water sports such as Scuba Diving. It is one of the schools that are constantly involved in conservation projects and development of dive sites. Safety always comes first. The professional and certified coaches welcome both beginners and novices to dive deep to explore life under water.",
    agenda:
      "Standup Paddling lessons are typically 1 hour, 30 minutes. You will spend the first few minutes learning all about the basics of Standup Paddling. You will be given safety instructions before learning the land drills. After learning how to surf on land, you will learn to paddle in the shallow waves under supervision. The timings are from 8 am to 11 am and 3 pm to 6 pm.",
    classes:
      "Beginners\
    Intermediate\
    Advanced\
    Group lessons\
    Individual lessons\
    Flexible classes:\
    3 days to 3 months\
    Paddle Rentals and Repairs",
    prerequisites: [
      "You do not need to have swimming knowledge to try out stand-up paddling.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in stand-up paddling",
      "You do not need to have prior experience to try out stand-up paddling.",
      "Fitness levels are not a prerequisite, anyone can try stand-up paddling.",
    ],
    testimonials: [],
    what_to_carry: [
      "Athleisure wear",
      "Slippers",
      "Towel",
      "Sunscreen (optional) ",
      "Light snacks for energy (optional)",
    ],
    faq: [
      {
        title: "What should I wear?",
        content:
          "Wear something you're comfortable in! Clothing that allows movement works best.",
      },
      {
        title: "Is stand up paddle boarding hard?",
        content: "SUP is one of the easiest water sport to participate in.",
      },
      {
        title: "is SUP easier than surfing?",
        content:
          "Yes, it requires less skill and energy as compared to surfing.",
      },
    ],
    prices: null,
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/standup_paddling/samudra/1.jpg",
    srcSet: "/images/content/card-pic-1@2x.jpg",
    gallery: [],
    location: "Maraimalai Adigal St, Chinna Veerampattinam, Puducherry 605007",
    google_map:
      "https://www.google.co.in/maps/dir/28.4552514,77.5046096/samudra+adventures/@19.9396542,68.7541595,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3a549f190105518f:0x1816b4b7ebe64cd5!2m2!1d79.8214397!2d11.8832775",
  },
};

const kayaking = {
  sport: "Kayaking",
  image: "/images/gallery/kayaking/hero.jpg",
  count: 6,
  vendors: [
    "bay-of-life",
    "surf-turf",
    "aquaoutback",
    "quest-academy",
    "temple-adventure",
    "urban-nomads",
  ],

  "bay-of-life": {
    title: "Bay Of Life",
    slug: "bay-of-life",
    tags: ["Learn", "Leisure", "Watersports", "Bay Of Life", "Chennai"],
    inclusion: ["Kayaking", "Safety Gear", " Professional trainers"],
    content:
      "The first kayaking school in India, Bay of Life boasts of Professional Instructors certified by national and international kayaking bodies. You are in safe hands with trusted knowledge of over 9 years. Child-friendly shallow beach - a safe haven for beginners and non-swimmers too. Kayaking is super fun and super safe.\n",
    location:
      "9/9, Muthumariamman Koil St, Kunnukadu, Kovalam, Tamil Nadu 603112",
    google_map:
      "https://www.google.co.in/maps/dir//bay+of+life/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3a525b122d86c629:0x19d9883c558fa118?sa=X&amp;ved=2ahUKEwjTnvjayID6AhVaELcAHWGHASUQ9Rd6BAhiEAU",
    prices: {
      leisure: { " Intro kayak lesson ": "INR 1500" },
      learn: { "Kayaking course 5 day": "INR 8000" },
    },
    agenda:
      "Intro kayaking Lesson - Learn the basics of kayaking | Beginner friendly | Child friendly \n5 Day kayaking Course - Learn kayaking in 5 days right from the basics",
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/kayaking/bay-of-life/1.jpg",
    srcSet: "/images/content/card-pic-1@2x.jpg",
    gallery: [],
    prerequisites: [
      "You do not need to have swimming knowledge to try out kayaking.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in kayaking",
      "You do not need to have prior experience to try out kayaking.",
      "Fitness levels are not a prerequisite, anyone can try kayaking.",
    ],
  },
  "surf-turf": {
    title: "Surf Turf",
    slug: "surf-turf",
    tags: ["Learn", "Leisure", "Watersports", "Surf Turf", "Chennai"],
    inclusion: ["Kayaking", "Safety Gear", " Professional trainers"],
    content:
      "Surf Turf surf school, one of the biggest kayaking schools, is a great place to meet new people and make new friends while you discover the exciting world of adventure water sports. Located across Kovalam, Mahabalipuram, Rameshwaram, and Chetpet, Surf Turf is all about exploring the world of kayaking with safety being the first priority. \n",
    location:
      "Plot No. 10, Pearl Beach, Ansari Nagar, Kovalam, Tamil Nadu 603112\n\n",
    google_map:
      "https://www.google.com/maps/place/Surf+Turf/@12.7862372,80.2543207,15z/data=!4m18!1m9!3m8!1s0x0:0xf931ff1ed08d6ac0!2sSurf+Turf!5m2!4m1!1i2!8m2!3d12.7862372!4d80.2543207!3m7!1s0x0:0xf931ff1ed08d6ac0!5m2!4m1!1i2!8m2!3d12.7862372!4d80.2543207",
    prices: {
      leisure: {
        "Intro Kayak lesson ": "INR 1900",
        "Regular Lesson Kayak": "INR 1250",
        "Discover Kids Kayaking": "INR 1250",
        "5 Day Kayak Course": " INR 2500",
        "10 Day Kayak Course": "INR 16520",
      },
    },
    rating: "4.8",
    reviews: "12",
    agenda:
      "Intro Kayak Lesson - Learn the basics of kayaking in 1.5 hours | Beginner friendly Regular Kayak Lesson - bask in the glory of waves for 1.5 hours\nDiscover Kids Kayaking - Special kayaking lesson of 1.5 hours for kids\n5 Day Surf Course - Learn surfing in 5 days right from the basics\n10 Day Surf Course - Go from beginner to advanced in just 10 days",
    src: "/images/gallery/kayaking/surf-turf/1.jpg",
    prerequisites: [
      "You do not need to have swimming knowledge to try out kayaking.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in kayaking",
      "You do not need to have prior experience to try out kayaking.",
      "Fitness levels are not a prerequisite, anyone can try kayaking.",
    ],
  },
  aquaoutback: {
    title: "Aquaoutback",
    slug: "aquaoutback",
    tags: ["Learn", "Leisure", "Watersports", "AquaOutback", "Chennai"],
    inclusion: ["Kayaking", "Safety Gear", " Professional trainers"],
    content:
      "AquaOutback is all about exploring safe adventure water sports in the vast coasts of Tuticorin. You will not only make friends but also explore the waters together. Learn Kayaking with world class safety and helpful, professional instructors.",
    location: "105, 2nd St, Toovipuram, Thoothukudi, Tamil Nadu 628003",
    google_map:
      "https://www.google.co.in/maps/dir/28.4552514,77.5046096/aqua+outback/@18.487879,69.9137437,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b03ef953699c09d:0xb69f2e19d5bb10dc!2m2!1d78.133699!2d8.802991",
    prices: {
      leisure: { " Intro Kayak lesson ": "INR 1500" },
      learn: {
        "Level 1 Kayaking": "INR 10000",
        "Level 2 Kayaking": "INR 15000",
        "Level 3 Kayaking": "INR 25000",
      },
    },
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/kayaking/aquaoutback/1.jpg",
    prerequisites: [
      "You do not need to have swimming knowledge to try out kayaking.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in kayaking",
      "You do not need to have prior experience to try out kayaking.",
      "Fitness levels are not a prerequisite, anyone can try kayaking.",
    ],
    agenda:
      "Intro Kayak Lesson - Learn the basics of kayaking | Beginner friendly | Child friendly \nLevel 1 kayaking - If you are familiar with the basics, level 1 kayaking is tailored to help you get better\nLevel 2 kayaking - Learn manoeveurs and tricks to embark on a kayaking journey\nlevel 3 kayaking - for pros to get better at kayaking",
  },
  "quest-academy": {
    title: "Quest Academy",
    slug: "quest-academy",
    tags: ["Learn", "Leisure", "Watersports", "Quest Academy", "Chennai"],
    inclusion: ["Kayaking", "Safety Gear", "Professional trainers"],
    content:
      "Explore the coasts of Rameswaram on your kayak! Quest Adventure Sports Academy is all about safe adventure water sports. Learn Kayaking and make new friends on your quest. Quest Academy is all about professional instructors, world class safety and the perfect kayaking experience.",
    location:
      "1/1164, Beach Road, Pirapanvalasai Village, Ramanathapuram, Tamil Nadu 623516",
    google_map:
      "https://www.google.co.in/maps/dir//quest+academy+asia/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3b01eb9613b2e173:0x7e34b4ffdfa1b32?sa=X&amp;ved=2ahUKEwjNlprBzIn6AhW-T2wGHdtRAFcQ9Rd6BAhPEAU",
    prices: {
      learn: {
        " Sea Kayaking Level 1 (recreation course) ": "INR 6000",
        "Sea Kayaking Level 2 (recreation course)": "INR 10000",
        " Sea Kayaking Level 3 (recreation course)": "INR 14000",
      },
    },
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/kayaking/quest-academy/1.jpg",
    prerequisites: [
      "You do not need to have swimming knowledge to try out kayaking.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in kayaking",
      "You do not need to have prior experience to try out kayaking.",
      "Fitness levels are not a prerequisite, anyone can try kayaking.",
    ],
    agenda:
      "Intro Kayak Lesson - Beginner friendly kayaking lesson of 2 hours duration\nSea Kayaking Level 1 (Recreation Course) - Kayak out at sea for a total duration of 7 hours over a course of 2-3 days\nSea Kayaking Level 2 (Recreation Course) - A blissful kayaking experience at sea for a total of 9 hours duration over a period of 4 days\nSea Kayaking Level 3 (Recreation Course) - A blissful kayaking experience at sea for a total of 12 hours duration over a period of 7 days",
  },
  "temple-adventure": {
    title: "Temple Adventure",
    slug: "temple-adventure",
    tags: ["Learn", "Leisure", "Watersports", "Temple Adventurem", "Chennai"],
    inclusion: ["Kayaking", "Safety Gear", " Professional trainers"],
    content:
      "Temple Adventure, located at Pondicherry is one of the best places to hang out for water based activities, especially Kayaking. Explore the soothing waters and make amazing memories at this beautiful, scenic locale. If you are in Pondicherry, head over to Temple Adventure and make new friends too!",
    location:
      "9 Archbishop Gandhi Street 1st Road Opposite Indira Gandhi, Colas Nagar, Puducherry, 605001",
    google_map:
      "https://www.google.co.in/maps/dir//temple+adventure+pondicherry/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3a53618654000001:0xe0fe344c4b5325cc?sa=X&amp;ved=2ahUKEwizz_rMzIn6AhVRS2wGHahxBHUQ9Rd6BAhnEAU",
    prices: {
      leisure: {
        " kayak session (half day) ": "INR 1000",
        " kayak session (full day)": "INR 2000",
      },
    },
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/kayaking/temple-adventure/1.jpg",
    prerequisites: [
      "You do not need to have swimming knowledge to try out kayaking.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in kayaking",
      "You do not need to have prior experience to try out kayaking.",
      "Fitness levels are not a prerequisite, anyone can try kayaking.",
    ],
    agenda:
      "Kayak Session (Half Day) - Cruise your way on your kayak in the blissful waters of Pondicherry for a 30 minute duration\nKayak Session (full day) - Go on a kayak cruise for a duration of 1 hour",
  },
  "urban-nomads": {
    title: "Urban nomads",
    slug: "urban-nomads",
    tags: ["Learn", "Leisure", "Watersports", "Urban nomads", "Chennai"],
    inclusion: ["Kayaking", "Safety Gear", " Professional trainers"],
    content:
      "Urban Nomads is an Adventure Water Sports School located along the Promenade beach of Pondicherry. This is a great place to hang out if you want to try out Kayaking with top notch safety. Professional instructors, top notch safety gear and pristine waters make this one of the best places to Kayak in India.",
    location: "3, St Theresa St, MG Road Area, Puducherry, 605001",
    google_map:
      "https://www.google.co.in/maps/dir//urban+nomads+pondicherry/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3a5363324bbf668f:0xf6ee374827931851?sa=X&amp;ved=2ahUKEwjlq-XZzIn6AhX5ZWwGHUDNCzQQ9Rd6BAhpEAU",
    rating: "4.8",
    reviews: "12",
    prerequisites: [
      "You do not need to have swimming knowledge to try out kayaking.",
      "Always bring a change of clothes.",
      "Anyone can try it out, there is no age/gender cap in kayaking",
      "You do not need to have prior experience to try out kayaking.",
      "Fitness levels are not a prerequisite, anyone can try kayaking.",
    ],
    src: "/images/gallery/kayaking/urban-nomads/1.jpg",
  },
};

const scuba_diving = {
  sport: "Scuba Diving",
  image: "/images/gallery/scuba_diving/hero.jpg",
  count: 7,
  vendors: [
    "dive-india",
    "salty-bones",
    "aquaoutback",
    "quest-academy",
    "temple-adventures",
    "urban-nomads",
    "samudra-aventures",
  ],
  "dive-india": {
    title: "Dive India",
    slug: "dive-india",
    inclusion: [
      "Scuba diving",
      "Safety gear",
      "Certified professional trainers",
      "Scuba diving gear",
    ],
    what_to_carry: [
      "Athleisure wear",
      "Hand towel",
      "Sunscreen (optional)",
      "Lotion (optional)",
      "Sunglasses (optional)",
      "Cap (optional)",
      "Change of clothes.",
    ],
    content:
      "Scuba Diving at Dive India is all about exploring the biodiversity under the water. It is totally safe and beginner friendly. You'll get to work with certified professional instructors while you learn all about scuba diving.",
    location:
      "DIVE INDIA Chennai, House no. 6, 1st Main street, VGP layout opp. Indian Maritme University, SH 49, Uthandi, Chennai, Tamil Nadu 600119",
    google_map:
      "https://www.google.co.in/maps/dir/28.4552514,77.5046096/dive+india+chennai/@19.2665214,68.334432,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3a55598fb88548f7:0x8ffd30030abd4c13!2m2!1d79.5753025!2d10.4712041",
    prices: {
      leisure: { " Discover Scuba program ": "INR 7000" },
      learn: {
        "PADI Open Water Course Full Entry Level Diver Certification Up to 18m ":
          "INR 28000",
        "PADI Advanced Open Water Course Upto 30 m": "INR 23000",
      },
    },
    classes:
      "PADI Open Water Course Full Entry Level Diver Certification Up to 18m - \nPADI Advanced Open Water Course Upto 30 m -",
    prerequisites: [
      "You do not need to have swimming knowledge to try out scuba diving.",
      "Anyone can try it out, there is no age/gender cap in scuba diving.",
      "You do not need to have prior experience to try out scuba diving.",
      "Fitness levels are not a prerequisite, anyone can try out scuba diving.",
      "If any pre-existing medical conditions exists, you need a clearence certificate from a doctor.",
    ],
    testimonials: [
      {
        user: "Pallavi Shanbhag, 29, Program Coordinator",
        rating: 4.3,
        review:
          "Scuba Diving was a very graceful experience for me. I'm not a great swimmer but I had fun learning the process and encountering so many life forms underwater. Highly recommend Dive India.",
      },
      {
        user: "Chitresh T, 22, Student ",
        rating: 4.0,
        review:
          "The instructors were very friendly and helpful. They were great diving buddies. All safety measures were in place. Great experience overall.",
      },
    ],
    faq: [
      {
        title: "Do I need to bring my own scuba gear?",
        content:
          "No! we will provide you with the scuba gear as well as all the safety gear.",
      },
      {
        title:
          "Should I receive medical clearance to participate in this activity?",
        content:
          "If you have any medical conditions then you require clearance from your doctor.",
      },
      {
        title: " I'm not a strong swimmer, is this going to be an issue?",
        content: "No, you do not need swimming skills to try out scuba diving.",
      },
      {
        title: "Is marine life dangerous?",
        content:
          "Our professional trainers will be by your side always and proper safety gear will be provided. Marine life is not dangerous.",
      },
    ],
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/scuba_diving/dive-india/1.jpg",
  },
  "salty-bones": {
    title: "Salty bones",
    slug: "salty-bones",
    inclusion: [
      "Scuba diving",
      "Safety gear",
      "Certified professional trainers",
      "Scuba diving gear",
    ],
    content:
      "With over 25 years of collective professional experience, Salty Bones is a popular zone for Scuba Divers - both beginners and advanced divers. There are also various certificate courses to choose from. It is a great place to hang out and meet new like minded people.",
    location:
      "2/171, SH 49, Cholamandal Artists Village, Injambakkam, Chennai, Tamil Nadu 600001",
    google_map:
      "https://www.google.co.in/maps/dir/28.4552514,77.5046096/salty+bones+chennai/@20.5288739,69.9168821,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3a53acaea3130583:0xe6f7f07a78c6fe55!2m2!1d80.2514609!2d12.9251247",
    prices: {
      leisure: {
        " TRY SCUBA": "INR 1900",
        "Discover Scuba program": "INR 7000",
        "FUN DIVE (for divers)": "INR 6500",
        "OCEAN DIVE (GROUP OF MAX 5) ": "INR 6500",
        " QUARRY DIVE (15 AND ABOVE) ": "INR 4500",
      },
    },
    classes:
      "TRY SCUBA - Experience the thrill of scuba diving \nDiscover Scuba program - Introductory 2-day scuba course for beginners \nFUN DIVE (for divers) - Avid scuba diver? Sign up for the fun dive with your gang and also meet other scuba divers on your journey to the bottom of the sea\nOCEAN DIVE (GROUP OF MAX 5) - Dive into the ocean and soar high on the adventure spirit with your gang \nQUARRY DIVE (15 AND ABOVE) -",
    prerequisites: [
      "You do not need to have swimming knowledge to try out scuba diving.",
      "Anyone can try it out, there is no age/gender cap in scuba diving.",
      "You do not need to have prior experience to try out scuba diving.",
      "Fitness levels are not a prerequisite, anyone can try out scuba diving.",
      "If any pre-existing medical conditions exists, you need a clearence certificate from a doctor.",
    ],

    what_to_carry: [
      "Athleisure wear",
      "Hand towel",
      "Sunscreen (optional)",
      "Lotion (optional)",
      "Sunglasses (optional)",
      "Cap (optional)",
      "Change of clothes.",
    ],
    testimonials: [
      {
        user: "Aditya Bhasin, 21, Student",
        rating: 4.3,
        review:
          "Though I am not a swimmer, I totally enjoyed Scuba Diving. Encountering life under the water was an exhilarating experience. Thanks to the professional instructors at Salty Bones.",
      },
      {
        user: "Nishant Vatchalam, 24, Architect",
        rating: 4.0,
        review:
          "I have always wanted to try water sports but was sceptical as I was a non-swimmer. Learning Scuba Diving at Salty Bones was a life-changing experience for me! State-of-the-art safety and mentorship.",
      },
    ],
    faq: [
      {
        title: "Do I need to bring my own scuba gear?",
        content:
          "No! we will provide you with the scuba gear as well as all the safety gear.",
      },
      {
        title:
          "Should I receive medical clearance to participate in this activity?",
        content:
          "If you have any medical conditions then you require clearance from your doctor.",
      },
      {
        title: " I'm not a strong swimmer, is this going to be an issue?",
        content: "No, you do not need swimming skills to try out scuba diving.",
      },
      {
        title: "Is marine life dangerous?",
        content:
          "Our professional trainers will be by your side always and proper safety gear will be provided. Marine life is not dangerous.",
      },
    ],
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/scuba_diving/salty-bones/1.jpg",
  },
  aquaoutback: {
    title: "Aquaoutback",
    slug: "aquaoutback",
    inclusion: [
      "Scuba diving",
      "Safety gear",
      "Certified professional trainers",
      "Scuba diving gear",
    ],
    what_to_carry: [
      "Athleisure wear",
      "Hand towel",
      "Sunscreen (optional)",
      "Lotion (optional)",
      "Sunglasses (optional)",
      "Cap (optional)",
      "Change of clothes.",
    ],
    content:
      "AquaOutback, located at Tuticorin is a great place for Scuba Diving. It is safe, fun and a must try offbeat water experience. With professional instructors and top notch safety gear, you are in safe hands. Enjoy the underwater biodiversity with your scuba diving skills.",
    location: "105, 2nd St, Toovipuram, Thoothukudi, Tamil Nadu 628003",
    google_map:
      "https://www.google.co.in/maps/dir/28.4552514,77.5046096/aqua+outback/@18.487879,69.9137437,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b03ef953699c09d:0xb69f2e19d5bb10dc!2m2!1d78.133699!2d8.802991",
    prices: {
      learn: {
        "OPEN WATER DIVE COURSE": "INR 25000",
        "Discover Scuba program": "INR 6500",
        "FUN DIVE (for divers)": "INR 5500",
      },
    },
    classes:
      "OPEN WATER DIVE COURSE - Learn scuba diving from scratch and dive in the open waters off the Chennai beach\nDiscover Scuba program - Explore what scuba diving is all about in this discovery program of 30 minutes \nFUN DIVE (for divers) - Avid scuba diver? Sign up for the fun dive with your gang and also meet other scuba divers on your journey to the bottom of the sea",
    prerequisites: [
      "You do not need to have swimming knowledge to try out scuba diving.",
      "Anyone can try it out, there is no age/gender cap in scuba diving.",
      "You do not need to have prior experience to try out scuba diving.",
      "Fitness levels are not a prerequisite, anyone can try out scuba diving.",
      "If any pre-existing medical conditions exists, you need a clearence certificate from a doctor.",
    ],
    testimonials: [
      {
        user: "Subhash Kashyap, 25, Customer Success Manager",
        rating: 4.3,
        review:
          "I took the beginners Scuba Diving lesson at AquaOutback. It was a truly good experience. The waters were clear and the visibility was great!  I intend to go back to learn more so that I can try more challenging landscapes.",
      },
      {
        user: "Apoorva Saraswat, 23, Developer",
        rating: 4.0,
        review:
          "I took the introductory Scuba Diving Lesson. I felt very comfortable in terms of the safety measures in place. Super professional instructors. Highly recommend AquaOutback.",
      },
    ],
    faq: [
      {
        title: "Do I need to bring my own scuba gear?",
        content:
          "No! we will provide you with the scuba gear as well as all the safety gear.",
      },
      {
        title:
          "Should I receive medical clearance to participate in this activity?",
        content:
          "If you have any medical conditions then you require clearance from your doctor.",
      },
      {
        title: " I'm not a strong swimmer, is this going to be an issue?",
        content: "No, you do not need swimming skills to try out scuba diving.",
      },
      {
        title: "Is marine life dangerous?",
        content:
          "Our professional trainers will be by your side always and proper safety gear will be provided. Marine life is not dangerous.",
      },
    ],
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/scuba_diving/aquaoutback/1.jpg",
  },
  "quest-academy": {
    title: "Quest Academy",
    slug: "quest-academy",
    inclusion: [
      "Scuba diving",
      "Safety gear",
      "Certified professional trainers",
      "Scuba diving gear",
    ],
    content:
      "Quest Adventure Sports Academy located on the Eastern Coast of India in Rameswaram, is one of the best Scuba Diving Schools in India. Professional and Certified Instructors help you learn scuba diving to explore life underwater. Top notch safety gear is provided as safety comes first.",
    location:
      "1/1164, Beach Road, Pirapanvalasai Village, Ramanathapuram, Tamil Nadu 623516",
    google_map:
      "https://www.google.co.in/maps/dir//quest+academy+asia/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3b01eb9613b2e173:0x7e34b4ffdfa1b32?sa=X&amp;ved=2ahUKEwjNlprBzIn6AhW-T2wGHdtRAFcQ9Rd6BAhPEAU",
    prices: {
      leisure: { " Discover Scuba program ": "INR 6000" },
      learn: { "Padi openwater course ": "INR 26000" },
    },
    classes:
      "Discover Scuba program - Discover what scuba diving is all about in this discovery course\nPadi openwater course -",
    prerequisites: [
      "You do not need to have swimming knowledge to try out scuba diving.",
      "Anyone can try it out, there is no age/gender cap in scuba diving.",
      "You do not need to have prior experience to try out scuba diving.",
      "Fitness levels are not a prerequisite, anyone can try out scuba diving.",
      "If any pre-existing medical conditions exists, you need a clearence certificate from a doctor.",
    ],
    what_to_carry: [
      "Athleisure wear",
      "Hand towel",
      "Sunscreen (optional)",
      "Lotion (optional)",
      "Sunglasses (optional)",
      "Cap (optional)",
      "Change of clothes.",
    ],
    testimonials: [
      {
        user: "Shashank Gupta, 31, Founder",
        rating: 4.3,
        review:
          "I always assumed that Scuba Diving would be difficult for a non-swimmer. My coach at Quest Adventure Sports Academy busted that myth. It is actually easy if you get the technique right. I was very happy with my experience.",
      },
      {
        user: "Rajeswari R, 21, Graphic Designer",
        rating: 4.0,
        review:
          " Quest Adventure Sports Academy surpassed my expectations! The coach was very kind and patient in teaching me scuba diving. Also, all safety measures were in place. I can happily say that I had a safe adventure.",
      },
    ],
    faq: [
      {
        title: "Do I need to bring my own scuba gear?",
        content:
          "No! we will provide you with the scuba gear as well as all the safety gear.",
      },
      {
        title:
          "Should I receive medical clearance to participate in this activity?",
        content:
          "If you have any medical conditions then you require clearance from your doctor.",
      },
      {
        title: " I'm not a strong swimmer, is this going to be an issue?",
        content: "No, you do not need swimming skills to try out scuba diving.",
      },
      {
        title: "Is marine life dangerous?",
        content:
          "Our professional trainers will be by your side always and proper safety gear will be provided. Marine life is not dangerous.",
      },
    ],
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/scuba_diving/quest-academy/1.jpg",
  },
  "temple-adventures": {
    title: "Temple Adventures",
    slug: "temple-adventures",
    inclusion: [
      "Scuba diving",
      "Safety gear",
      "Certified professional trainers",
      "Scuba diving gear",
    ],
    what_to_carry: [
      "Athleisure wear",
      "Hand towel",
      "Sunscreen (optional)",
      "Lotion (optional)",
      "Sunglasses (optional)",
      "Cap (optional)",
      "Change of clothes.",
    ],
    content:
      "Located at Pondicherry, Temple Adventures is all about open deep sea diving. Safety is of utmost importance - all safety gear and instructions are provided. You can dive as seep as 40 feet to explore the biodiversity under water. Temple Adventures holds a 100% safety record, with a combined teaching experience of over a hundred years.",
    location:
      "9 Archbishop Gandhi Street 1st Road Opposite Indira Gandhi, Colas Nagar, Puducherry, 605001",
    google_map:
      "https://www.google.co.in/maps/dir//temple+adventure+pondicherry/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3a53618654000001:0xe0fe344c4b5325cc?sa=X&amp;ved=2ahUKEwizz_rMzIn6AhVRS2wGHahxBHUQ9Rd6BAhnEAU",
    prices: {
      learn: {
        "PADI DISCOVER SCUBA DIVING COURSE": "INR 7500",
        "PADI OPEN WATER COURSE": "INR 34000",
        "PADI UNDERWATER DIGITALPHOTOGRAPHY COURSE": "INR 17000",
        "COUPLE PRIVATE SCUBA DIVING": "INR 43540",
      },
    },
    classes:
      "There are beginner friendly lessons, refresher courses and advanced courses. First, the theory will be explained along with all the standing instructions and safety measures. Then you will dive in the open ocean waters under supervision. Diving sessions are usually about 30-60 minutes.",
    prerequisites: [
      "You do not need to have swimming knowledge to try out scuba diving.",
      "Anyone can try it out, there is no age/gender cap in scuba diving.",
      "You do not need to have prior experience to try out scuba diving.",
      "Fitness levels are not a prerequisite, anyone can try out scuba diving.",
      "If any pre-existing medical conditions exists, you need a clearence certificate from a doctor.",
    ],
    testimonials: [
      {
        user: "Antony Hyson, 21, Videographer",
        rating: 4.3,
        review:
          "Do not go in with expectations and you will surprise yourself! I did not dive in hopes of finding whales!! I was amazed at all the different creatures we encountered. Worth a try.",
      },
      {
        user: "Aditya Bhasin, 21, Student",
        rating: 4.0,
        review:
          "Scuba Diving was a safe, yet thrilling experience for me. I loved the part where we had to dive from the boat. The instructors were very professional and helpful.",
      },
    ],
    faq: [
      {
        title: "Do I need to bring my own scuba gear?",
        content:
          "No! we will provide you with the scuba gear as well as all the safety gear.",
      },
      {
        title:
          "Should I receive medical clearance to participate in this activity?",
        content:
          "If you have any medical conditions then you require clearance from your doctor.",
      },
      {
        title: " I'm not a strong swimmer, is this going to be an issue?",
        content: "No, you do not need swimming skills to try out scuba diving.",
      },
      {
        title: "Is marine life dangerous?",
        content:
          "Our professional trainers will be by your side always and proper safety gear will be provided. Marine life is not dangerous.",
      },
    ],
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/scuba_diving/temple-adventures/1.jpg",
  },
  "urban-nomads": {
    title: "Urban Nomads",
    slug: "urban-nomads",
    inclusion: [
      "Scuba diving",
      "Safety gear",
      "Certified professional trainers",
      "Scuba diving gear",
    ],
    content:
      "Urban Nomads is one of the best schools to learn Scuba Diving in Pondicherry. It is all about fun, adventure and experiences with safety being the first priority. The certified professional instructors help you learn scuba diving from scratch - explore what lies beneath the calm serene waters of the ocean.",
    location: "3, St Theresa St, MG Road Area, Puducherry, 605001",
    google_map:
      "https://www.google.co.in/maps/dir//urban+nomads+pondicherry/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3a5363324bbf668f:0xf6ee374827931851?sa=X&amp;ved=2ahUKEwjlq-XZzIn6AhX5ZWwGHUDNCzQQ9Rd6BAhpEAU",
    prices: {
      learn: {
        " Open Water Diver Course": "INR 34000",
        "Discover Scuba Diver Course": "INR 7500",
        "Scuba For Kids": "INR 4500",
      },
    },
    classes:
      "Open Water Diver Course - \nDiscover Scuba Diver Course - Discover what scuba diving is all about in this exploratory scuba diving course\nScuba For Kids - Specially designed scuba course for kids who love adventure water sports",
    prerequisites: [
      "You do not need to have swimming knowledge to try out scuba diving.",
      "Anyone can try it out, there is no age/gender cap in scuba diving.",
      "You do not need to have prior experience to try out scuba diving.",
      "Fitness levels are not a prerequisite, anyone can try out scuba diving.",
      "If any pre-existing medical conditions exists, you need a clearence certificate from a doctor.",
    ],
    what_to_carry: [
      "Athleisure wear",
      "Hand towel",
      "Sunscreen (optional)",
      "Lotion (optional)",
      "Sunglasses (optional)",
      "Cap (optional)",
      "Change of clothes.",
    ],
    testimonials: [
      {
        user: "Shashank Gupta, 31, Founder",
        rating: 4.3,
        review:
          "The instructor was very patient in explaining safety rules and the usage of equipment. After the pool practice session, I was very comfortable diving into the ocean. Super happy with my experience",
      },
      {
        user: "Rajeswari R, 21, Graphic Designer",
        rating: 4.0,
        review:
          "My partner and I had a wholesome experience learning Scuba Diving. We want to go back to try out other water sports options too!",
      },
    ],
    faq: [
      {
        title: "Do I need to bring my own scuba gear?",
        content:
          "No! we will provide you with the scuba gear as well as all the safety gear.",
      },
      {
        title:
          "Should I receive medical clearance to participate in this activity?",
        content:
          "If you have any medical conditions then you require clearance from your doctor.",
      },
      {
        title: " I'm not a strong swimmer, is this going to be an issue?",
        content: "No, you do not need swimming skills to try out scuba diving.",
      },
      {
        title: "Is marine life dangerous?",
        content:
          "Our professional trainers will be by your side always and proper safety gear will be provided. Marine life is not dangerous.",
      },
    ],
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/scuba_diving/urban-nomads/1.jpg",
  },
  "samudra-aventures": {
    title: "Samudra Adventures",
    slug: "samudra-aventures",
    inclusion: [
      "Scuba diving",
      "Safety gear",
      "Certified professional trainers",
      "Scuba diving gear",
    ],
    what_to_carry: [
      "Athleisure wear",
      "Hand towel",
      "Sunscreen (optional)",
      "Lotion (optional)",
      "Sunglasses (optional)",
      "Cap (optional)",
      "Change of clothes.",
    ],
    content:
      "Samudra Adventures located at Pondicherry is actively involved in promoting adventure water sports such as Scuba Diving. It is one of the schools that are constantly involved in conservation projects and development of dive sites. Safety always comes first. The professional and certified coaches welcome both beginners and novices to dive deep to explore life under water.",
    location: "Maraimalai Adigal St, Chinna Veerampattinam, Puducherry 605007",
    google_map:
      "https://www.google.co.in/maps/dir/28.4552514,77.5046096/samudra+adventures/@19.9396542,68.7541595,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3a549f190105518f:0x1816b4b7ebe64cd5!2m2!1d79.8214397!2d11.8832775",
    prices: {
      learn: {
        "Discover Scuba program": "INR 7000",
        "OPEN WATER COURSE": "INR 25000",
      },
    },
    classes:
      "Discovering Scuba Diving starts from understanding the basics - theory, technique, usage and handling of equipment and then the diving experience itself. The introductory lesson is a one day experience which eases you into scuba diving up to a depth of 12 meters.",
    prerequisites: [
      "You do not need to have swimming knowledge to try out scuba diving.",
      "Anyone can try it out, there is no age/gender cap in scuba diving.",
      "You do not need to have prior experience to try out scuba diving.",
      "Fitness levels are not a prerequisite, anyone can try out scuba diving.",
      "If any pre-existing medical conditions exists, you need a clearence certificate from a doctor.",
    ],
    testimonials: [
      {
        user: "Anmol Arora, 30, Founder",
        rating: 4.3,
        review:
          "The whole scuba diving experience was one of a kind for me. I dont swim and I had my own apprehensions. The instructor was very kind and helpful and before I knew it, I was encountering colourful fish underwater. Highly recommend.",
      },
      {
        user: "Prakya Vasudevan, 27, Product Manager",
        rating: 4.0,
        review:
          "The coaches were very patient and gave utmost importance to safety. Highly recommend Samudra Adventures for Scuba Diving In Pondicherry.",
      },
    ],
    faq: [
      {
        title: "Do I need to bring my own scuba gear?",
        content:
          "No! we will provide you with the scuba gear as well as all the safety gear.",
      },
      {
        title:
          "Should I receive medical clearance to participate in this activity?",
        content:
          "If you have any medical conditions then you require clearance from your doctor.",
      },
      {
        title: " I'm not a strong swimmer, is this going to be an issue?",
        content: "No, you do not need swimming skills to try out scuba diving.",
      },
      {
        title: "Is marine life dangerous?",
        content:
          "Our professional trainers will be by your side always and proper safety gear will be provided. Marine life is not dangerous.",
      },
    ],
    rating: "4.8",
    reviews: "12",
    src: "/images/gallery/scuba_diving/samudra-aventures/1.jpg",
  },
};

const vendors = {};
vendors["surfing"] = surfing;
vendors["standup_paddling"] = standup_paddling;
vendors["kayaking"] = kayaking;
vendors["scuba_diving"] = scuba_diving;

export default vendors;
export const stays = [];
export const stays1 = [];
export const stays2 = [];
