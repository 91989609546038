import React, { useState } from "react";
import cn from "classnames";
import styles from "./Footer.module.sass";
import { Link } from "react-router-dom";
import Image from "../Image";
import Icon from "../Icon";
import Theme from "../Theme";
import Form from "../Form";

const items = [
  {
    title: "Explore",
    url: "/explore",
  },
  {
    title: "About",
    url: "/#about",
  },
  {
    title: "Gallery",
    url: "/#gallery",
  },
  {
    title: "Testimonials",
    url: "/#testimonials",
  },
  // {
  //   title: "Contact Us",
  //   url: "/contact",
  // },
];

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <div className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/images/logo-wb.png"
                srcDark="/images/logo-db.png"
                alt="sportify-logo"
              />
            </Link>
            <div className={styles.add}>
              No 5/3 Damodaran street Kellys, Kilpauk, Chennai 600010
              <br />
            </div>
            <div>admin@sportifyingindia.com</div>
            <div>+91 76038 48498</div>

            {/* <div className={styles.box}>
              <Icon name="bulb" size="20" />
              <span>Dark theme</span>
              <Theme className={styles.theme} />
            </div> */}
          </div>
          <div className={styles.col}>
            <div className={styles.menu}>
              {items.map((x, index) => (
                <a className={styles.link} href={x.url} key={index}>
                  {x.title}
                </a>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              Say Hi{" "}
              <span role="img" aria-label="fire">
                🔥
              </span>
            </div>
            <div className={styles.social}>
              <a href="https://www.instagram.com/sportifying.india/?hl=en">
                <img
                  className={styles.img_pad}
                  src="/images/iglogo.svg"
                  alt="InstaLogo"
                />
              </a>
              <a href="https://wa.me/+917603848498?text=Hey%20Sportifying%20India,%20I%20want%20more%20information%20on%20various%20experience%20provided%20by%20you.%20Thanks">
                <img
                  className={styles.img_pad}
                  src="/images/walogo.svg"
                  alt="WhatsAppLogo"
                />
              </a>
              {/* <a>
                <img
                  className={styles.img_pad}
                  src="/images/twlogo.svg"
                  alt="TwitterLogo"
                />
              </a> */}
            </div>
            {/* <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={() => handleSubmit()}
              placeholder="Enter your email"
              type="email"
              name="email"
              icon="arrow-next"
            /> */}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.copyright}>
            Copyright © 2022 Sportifyingind Sports LLP. All rights
            reserved
          </div>
          <div className={styles.policy}>
            <Link to="/privacy" className={styles.link}>
              Privacy
            </Link>
            <Link to="/refund" className={styles.link}>
              Refund
            </Link>
            <Link to="/terms" className={styles.link}>
              Terms
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
