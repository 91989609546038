export const packagesList = [
  {
    title: "Leisure",
    icon: "coin",
    items: [
      {
        title: "Water Sports Package",
        location: "Chennai Harbour",
        image: "/images/content/water-sport.png",
        image2x: "/images/content/water-sport.png",
        url: "watersport",
      },
      {
        title: "Relaxed Package",
        location: "Kovalam, Chennai",
        image: "/images/content/relaxed-package.png",
        image2x: "/images/content/relaxed-package.png",
        url: "watersport",
      },
      {
        title: "Couple Package",
        location: "Kovalam, Chennai",
        image: "/images/content/couple-package.png",
        image2x: "/images/content/couple-package.png",
        url: "watersport",
      },
      {
        title: "Friends package",
        location: "Kovalam, Chennai",
        image: "/images/content/friends-package.png",
        image2x: "/images/content/friends-package.png",
        url: "watersport",
      },
    ],
  },
];
