import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { Link } from "react-router-dom";
import vendors from "../../../mocks/stays";

const Item = ({ sport, item, className }) => {
  console.log("vendor item >>>>>>>", { item });
  return (
    <Link
      className={cn(styles.item, className)}
      to={`/event-details/${sport}/${item}`}
    >
      <div className={cn("status-gray", styles.number)}>
        {vendors[sport][item].rating}
      </div>
      <div className={styles.preview}>
        <img src={vendors[sport][item].src} alt="Place" />
      </div>
      <div className={styles.title}>{vendors[sport][item].title}</div>
      {/* <div className={styles.time}>{item.time}</div> */}
    </Link>
  );
};

export default Item;
