import React from "react";
import Main from "./Main";
import Catalog from "./Catalog";
import Places from "../../components/Places";
import Package from "../Home/Package";
import Newsletter from "../../components/Newsletter";
import Best from "../../components/Best";
import Testimonials from "../../components/Testimonials";
import { useParams } from "react-router";
import vendors from "../../mocks/stays";

const EventCategory = () => {
  const { sport } = useParams();

  return (
    <>
      <Main sport={sport} />
      <Catalog sport={sport} />
      <Places
        classSection="section-mb80"
        title="Explore Experiences"
        sport={sport}
        // info={vendors[sport].count}
      />
      <Package />
      {/* <Newsletter />
      <Best
        classSection="section-mb0"
        title="Superpartner"
        info="300+ superpartner"
      /> */}
      <Testimonials classSection="section-pd section-mb0" />
    </>
  );
};

export default EventCategory;
