import React from "react";
import cn from "classnames";
import styles from "./VendorGallery.module.sass";
import Product from "../../components/Product";
import Icon from "../../components/Icon";
import { Link, useParams } from "react-router-dom";
import Masonry from "react-masonry-css";
import GalleryHeader from "../../components/GalleryHeader";
import { useEffect } from "react";
import { useState } from "react";

const breakpointCols = {
  default: 2,
  768: 1,
};

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Stays",
    url: "/",
  },
  {
    title: "New Zealand",
    url: "/stays-category",
  },
  {
    title: "South Island",
  },
];
const sport_vendor = (sport, vendor) => `/images/gallery/${sport}/${vendor}`;

const gallery_link = (sport_vendor, i) => `${sport_vendor}/${i}.jpg`;

const gallery = (sport, vendor) => {
  let gall_list = [];
  const sv = sport_vendor(sport, vendor);

  for (let i = 0; i <= 15; i++) {
    gall_list.push(gallery_link(sv, i));
  }
  return gall_list;
};

const options = [
  {
    title: "Superhost",
    icon: "home",
  },
  {
    title: "Queenstown, Otago, New Zealand",
    icon: "flag",
  },
];

const VendorGallery = () => {
  const { sport, vendor } = useParams();
  const [gal, setGal] = useState([]);

  useEffect(() => {
    const g = gallery(sport, vendor);
    console.log({ g });
    setGal(g);
  }, []);

  return (
    <>
      <GalleryHeader
        classSection="section-mb64"
        urlHome="/"
        title="Browse Unfiltered Photos"
        breadcrumbs={breadcrumbs}
        options={options}
      ></GalleryHeader>

      <div className={cn("section-mb80", styles.section)}>
        <div className={cn("container", styles.container)}>
          {/* <div className={styles.head}>
            <div className={styles.box}>
              <h1 className={cn("h2", styles.title)}>
                Browse Unfiltered Photos
              </h1>
            </div>
          </div> */}
          {/* {gal?.map(
            (x, index) =>
              index === 0 && (
                <div className={styles.preview} key={index}>
                  <img src={x} alt="Nature" />
                </div>
              )
          )} */}
          <div className={styles.inner}>
            <Masonry
              className={styles.grid}
              columnClassName={styles.column}
              breakpointCols={breakpointCols}
            >
              {gal.map(
                (x, index) =>
                  index > 0 && (
                    <div className={styles.preview} key={index}>
                      <img src={x} alt="Nature" />
                    </div>
                  )
              )}
            </Masonry>
          </div>
          <div className={styles.foot}>
            <Link
              to="/"
              className={cn("button-circle-stroke button-small", styles.button)}
            >
              <Icon name="close" size="24" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorGallery;
