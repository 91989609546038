import React from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "../Control";

const GalleryHeader = ({
  classSection,
  urlHome,
  breadcrumbs,
  title,
  options,
  gallery,
  type,
  rating,
}) => {
  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <Control
          className={styles.control}
          urlHome={urlHome}
          breadcrumbs={[]}
        />
        <div className={styles.head}>
          <div className={styles.box}>
            <h1 className={cn("h2", styles.title)}>{title}</h1>
          </div>
          {/* <Actions className={styles.actions} /> */}
        </div>
      </div>
    </div>
  );
};

export default GalleryHeader;
