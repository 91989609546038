import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";
import Panel from "../../../components/Panel";
import Location from "../../../components/Location";
import Content from "../../../components/Content";
import DateRange from "../../../components/DateRange";
import Travelers from "../../../components/Travelers";

const Main = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.preview}>
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet="/images/content/main-hero-mobile.png"
            />
            <div className={styles.hero}>
              <img src="/images/content/main-hero-1.jpg" alt="Main" />
            </div>
          </picture>
          <div className={styles.wrap}>
            <h1 className={cn("hero", styles.title)}>Discover</h1>
            <h1 className={cn("hero", styles.title2)}>offbeat experiences</h1>
            <h1 className={cn("hero", styles.title)}>near you</h1>

            {/* <p className={cn("info", styles.info)}>
              Get amazing deals and unlock exciting community rewards!
            </p> */}
            <Link className={cn("button", styles.button)} to="/event-category/sail-course">
              Explore Events
            </Link>
          </div>
        </div>
        <Panel
          className={styles.panel}
          menu
          classBody={styles.body}
          onSearch={() => console.log("Search")}
        >
          <div className={styles.row}>
            <Content
              icon="location"
              description="Happy Customers"
              placeholder="🥰 500+"
            />
            <Content
              icon="location"
              description="Community Members"
              placeholder="🤙🏻 6,000+"
            />
            <Content
              icon="location"
              description="Sporting Partners"
              placeholder="🙌🏻 50+"
            />
            <Content
              icon="location"
              description="Outdoor Events"
              placeholder="🥳 10+"
            />
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default Main;
