import React, { useState } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <h1 className={cn("hero", styles.title)}>Terms & Conditions</h1>
        {/* <div className={styles.info}>
          Last updated on 05 June 2022 Welcome to the Sportifying India club!
          This Privacy Policy and Terms and Conditions is issued by Sportifying
          India Pvt Ltd. for the usage of https://www.sportifyingindia.com:.
        </div> */}
        <div className={styles.egLggN}>
          <div>
            {/* <h3>
              <strong>Eligibility</strong>
            </h3> */}
            <p>
              The terms ‘Sportiying India”/"We" / "Us" / "Our"/”Company”
              individually and collectively refer to Sportifyingind Sports LLP
              and the terms "Visitor” ”User” refer to the users.
            </p>
            <p>
              This page states the Terms and Conditions under which you
              (Visitor) may visit this website (“sportfyingindia.com”). Please
              read this page carefully. If you do not accept the Terms and
              Conditions stated here, we would request you to exit this site.
              The business, any of its business divisions and / or its
              subsidiaries, associate companies or subsidiaries to subsidiaries
              or such other investment companies (in India or abroad) reserve
              their respective rights to revise these Terms and Conditions at
              any time by updating this posting. You should visit this page
              periodically to re-appraise yourself of the Terms and Conditions,
              because they are binding on all users of this Website.
            </p>
            <p>
              <h3>Acceptable website use</h3>
              <ul>
                <li>
                  <strong>Security Rules: </strong>
                  Visitors are prohibited from violating or attempting to
                  violate the security of the Web site, including, without
                  limitation, (1) accessing data not intended for such user or
                  logging into a server or account which the user is not
                  authorised to access, (2) attempting to probe, scan or test
                  the vulnerability of a system or network or to breach security
                  or authentication measures without proper authorisation, (3)
                  attempting to interfere with service to any user, host or
                  network, including, without limitation, via means of
                  submitting a virus or "Trojan horse" to the Website,
                  overloading, "flooding", "mail bombing" or "crashing", or (4)
                  sending unsolicited electronic mail, including promotions
                  and/or advertising of products or services. Violations of
                  system or network security may result in civil or criminal
                  liability. The business and / or its associate entities will
                  have the right to investigate occurrences that they suspect as
                  involving such violations and will have the right to involve,
                  and cooperate with, law enforcement authorities in prosecuting
                  users who are involved in such violations.
                </li>
                <li>
                  <strong>General Rules: </strong>
                  Visitors may not use the Website in order to transmit,
                  distribute, store or destroy material (a) that could
                  constitute or encourage conduct that would be considered a
                  criminal offence or violate any applicable law or regulation,
                  (b) in a manner that will infringe the copyright, trademark,
                  trade secret or other intellectual property rights of others
                  or violate the privacy or publicity of other personal rights
                  of others, or (c) that is libellous, defamatory, pornographic,
                  profane, obscene, threatening, abusive or hateful.
                </li>
              </ul>
            </p>

            <h3>Indemnity</h3>
            <p>
              The User unilaterally agree to indemnify and hold harmless,
              without objection, the Company, its officers, directors, employees
              and agents from and against any claims, actions and/or demands
              and/or liabilities and/or losses and/or damages whatsoever arising
              from or resulting from their use of Sportifyingindia.com or their
              breach of the terms .
            </p>

            <h3>Disclaimer of Consequential Damages</h3>
            <p>
              In no event shall Company or any parties, organizations or
              entities associated with the corporate brand name us or otherwise,
              mentioned at this Website be liable for any damages whatsoever
              (including, without limitations, incidental and consequential
              damages, lost profits, or damage to computer hardware or loss of
              data information or business interruption) resulting from the use
              or inability to use the Website and the Website material, whether
              based on warranty, contract, tort, or any other legal theory, and
              whether or not, such organization or entities were advised of the
              possibility of such damages.
            </p>
            <h3>Limited License</h3>
            <p>
              Sportifying India grants you a non-exclusive, non-transferable,
              revocable license to access and use our Website strictly in
              accordance with our Legal Terms. Your use of our Website is solely
              for the purposes as provided herein.
            </p>
            <p>
              The Website, and all content, materials, information, software,
              products and services provided on the Website, are provided on an
              'as is' and 'as available' basis.
            </p>
            <h3>Our Relationship to You</h3>
            <p>
              Sportifying India is strictly a venue that does NOT enter into any
              other relationship with you, other than that of an independent
              contractor. Our Legal Terms in no way create any agency,
              partnership, joint venture, employee-employer or
              franchisor-franchisee relationship between you and Sportifying
              India , other Users, or our affiliates.
            </p>
            <h3>Legal Compliance</h3>
            <p>
              You agree to comply with all applicable domestic and international
              laws, statutes, ordinances, and regulations regarding your use of
              our Website. Sportifying India reserves the right to investigate
              complaints or reported violations of our Legal Terms and to take
              any action we deem appropriate, including but not limited to
              cancelling your Member account, reporting any suspected unlawful
              activity to law enforcement officials, regulators, or other third
              parties and disclosing any information necessary or appropriate to
              such persons or entities relating to your profile, email
              addresses, usage history, posted materials, IP addresses and
              traffic information, as allowed under our Privacy Policy.
            </p>
            <h3>Eligibility and Registration for Membership</h3>
            <p>
              You may view some of our Content without becoming a Member of our
              Website. However, to use our Website, you may have to register and
              become a Member. Your membership is not transferable or assignable
              and is void where prohibited. You certify that you are at least 18
              years of age or older. If you are between the ages of 13 and 18,
              you certify that you have your parent's permission to join our
              Website and become a Member. You may not access this website if
              you are under the age of 13.
            </p>
            <p>
              By registering as a supplier, you are certifying that you are a
              legitimate tour or travel-related business and that you are the
              actual owner or authorized representative of the business and have
              the right to register your business with our Website.
            </p>
            <p>
              Any registration by any Member in contravention of the above
              limitations is unauthorized, unlicensed and in violation of our
              Legal Terms. You agree to and to abide by all of the terms and
              conditions of our Legal Terms. Sportifying India has the sole
              right and discretion to determine whether to accept a Member, and
              may reject a Member's registration, with or without explanation.
            </p>
            <p>
              When you complete the registration process, you will create a
              username and password that will allow you to access our Website.
              You agree to maintain the confidentiality of your password and are
              fully responsible for all liability and damages resulting from
              your failure to maintain that confidentiality and all activities
              that occur through the use of your password. You agree to
              immediately notify us of any authorised use of your password or
              any other breach of security regarding your account or
              information. You agree that Sportifying India shall not be liable
              for any loss or damage arising from your failure to comply with
              password security as discussed herein.
            </p>
            <h3>Information From Children</h3>
            <p>
              We do not knowingly solicit, collect or retain information from
              any individuals under the age of 13.
            </p>
            <h3>Supplier and Sales of Goods/Services</h3>
            <p>
              As a supplier, you can upload information about your business,
              including address, phone, hours of operation, and other such
              relevant information. By uploading such information, you warrant
              to Sportifying India that such information is true to the best of
              your knowledge and is not in any way malicious, libelous, or
              otherwise illegal for you to transmit to our Website.
            </p>
            <p>
              Our Website offers suppliers the opportunity to sell their
              products and services through our Website. This service is
              strictly as a convenience to our Members and in no way means that
              Sportifying India endorses a specific supplier, guarantees their
              products/services, or that Sportifying India participates in any
              way in the sale of such products/services. Any purchases of
              products/services that a Customer may make through our Website are
              strictly between the supplier and Customer.
            </p>
            <p>
              By interacting through our Website as supplier and Customer, you
              both agree that at all times, you will look to each other for any
              issues or information related to such sale of products/services,
              including, but not limited to, costs, scope of services, product
              features, payment terms, late or non-payment issues, delivery
              dates, shipping information, refunds, exchanges, and warranties.
            </p>
            <p>
              As a supplier, if you contact Customers outside our Website with
              correspondence, you agree to abide by such Customers' requests to
              be removed from future correspondence. If we receive complaints
              that you are failing to do so, we reserve the right to terminate
              your Profile at our sole discretion with no notice to you.
            </p>
            <h3>Member Content</h3>
            <p>
              As a Member, any information you upload to our Website is
              considered Member Content. By uploading Member Content, you
              warrant to Sportifying India that you have legal right, whether by
              ownership, license, or otherwise, to transmit, display, and use
              the underlying associated text and/or images. You are solely
              responsible for ensuring that you do not infringe the rights of
              any third parties in such Member Content and for any legal damages
              caused by your transmission, use, or display of Member Content.
            </p>
            <p>
              You understand Member Content may contain material that someone
              may consider offensive, indecent, or objectionable. We have no
              control over Member Content provided by Members and do not in any
              way guarantee the quality, accuracy or integrity of such Member
              Content. Sportifying India is not responsible for the monitoring
              or filtering of any Member Content. Should any Member Content be
              found illegal, Sportifying India will submit all necessary
              information to the proper authorities.
            </p>
            <p>
              if your Member Content is reported to Sportifying India as being
              in violation of third party rights (i.e. copyrights), or is
              reported as offensive or inappropriate, we may ask you to retract
              or otherwise modify the questionable Member Content within 24
              hours of being notified by Sportifying India . Should you fail to
              comply with such a request or should Sportifying India otherwise
              deem it necessary, Sportifying India has full authority and sole
              discretion to remove the offending Member Content, restrict your
              ability to post further Member Content, and in our sole
              discretion, to immediately terminate your account without further
              notification to you.
            </p>
            <p>
              You acknowledge that Sportifying India is NOT liable for loss or
              damage to Member Content nor do we warrant that we may have a
              backup of your Member Content. You should always maintain your own
              backup copies of Member Content.
            </p>
            <p>
              Sportifying India does not make any ownership claims to your
              Member Content other than as noted within our Legal Terms.
              However, by uploading Member Content, you grant Sportifying India
              a perpetual, world-wide, royalty-free, irrevocable license to use,
              display, and redistribute your Member Content in any form we see
              fit.
            </p>
            <h3>Member Conduct</h3>
            <p>
              As a Member, you agree not to use our Website to do any of the
              following:
              <ul>
                <li>
                  Violates any local, state, federal, or international laws.
                </li>
                <li>
                  Infringes on any patent, trademark, trade secret, copyright or
                  other proprietary rights of any party.
                </li>
                <li>
                  Harms, threatens, defames, promotes violence or illegal
                  activities, or is otherwise vulgar, obscene, abusive,
                  harassing, tortuous, libelous, invasive of another's privacy,
                  hateful, or racially, ethically or otherwise objectionable.
                </li>
                <li>
                  Links directly or indirectly to any materials to which you do
                  not have a right to link.
                </li>
                <li>
                  Contains any private information of any third party,
                  including, without limitation, addresses, phone numbers, email
                  addresses, government issued tax or identification numbers and
                  credit card numbers
                </li>
                <li>
                  Contains Website viruses or any other computer code, files or
                  programs designed to interrupt, destroy or limit the
                  functionality of any computer Website or hardware or
                  telecommunications equipment, or to extract information from
                  our Website.
                </li>
                <li>
                  Contains Website viruses or any other computer code, files or
                  programs designed to interrupt, destroy or limit the
                  functionality of any computer Website or hardware or
                  telecommunications equipment, or to extract information from
                  our Website.
                </li>
              </ul>
            </p>
            <p>
              You do not have a right to transmit under any law (i.e.
              intellectual property laws) or under contractual or fiduciary
              relationships (i.e. non-disclosure Agreements).you do not have a
              right to transmit under any law (i.e. intellectual property laws)
              or under contractual or fiduciary relationships (i.e.
              non-disclosure Agreements)
            </p>
            <p>
              In the sole judgment of Sportifying India is objectionable or
              which restricts or inhibits any other person from using or
              enjoying our Website, or which may expose Sportifying India, our
              affiliates, or our Users to any harm or liability of any type.in
              the sole judgment of Sportifying India is objectionable or which
              restricts or inhibits any other person from using or enjoying our
              Website, or which may expose Sportifying India, our affiliates, or
              our Users to any harm or liability of any type.
            </p>
            <h3>Use Of Content</h3>
            <p>
              All logos, brands, marks headings, labels, names, signatures,
              numerals, shapes or any combinations thereof, appearing in this
              site, except as otherwise noted, are properties either owned, or
              used under license, by the business and / or its associate
              entities who feature on this Website. The use of these properties
              or any other content on this site, except as provided in these
              terms and conditions or in the site content, is strictly
              prohibited.
            </p>
            <p>
              You may not sell or modify the content of this Website or
              reproduce, display, publicly perform, distribute, or otherwise use
              the materials in any way for any public or commercial purpose
              without the respective organisation’s or entity’s written
              permission.
            </p>
            <h3>Use our Content to:</h3>
            <p>
              <ul>
                <li>
                  Develop a competing Website or help anyone else to do the
                  same.
                </li>
                <li>
                  Create compilations or derivative works as defined under
                  copyright laws.
                </li>
                <li>
                  Re-distribute it in any manner, including, but not limited to,
                  sale, license, lease, rental, subscription, or any other
                  distribution mechanism.
                </li>
                <li>
                  Decompile, disassemble or reverse engineer our Website and any
                  related Website.
                </li>
                <li>
                  Use our Website in any manner that violates our Legal Terms or
                  any local, state, federal, or international laws.
                </li>
              </ul>
            </p>
            <h3>Use your Account to:</h3>
            <p>
              <ul>
                <li>Allow another person to login as you.</li>
                <li>
                  Violate any local, state, federal, or international laws.
                </li>
              </ul>
            </p>
            <h3>Intellectual Property</h3>
            <p>
              Our Website may contain our trademarks as well as those of
              suppliers, our affiliates, and other companies, in the form of
              words, graphics, and logos. Your use of our Website does not
              constitute any right or license for you to use such a trademark,
              without the prior written permission of the corresponding
              trademark owner. Our Website is also protected under international
              copyrights.
            </p>
            <p>
              The copying, redistribution, use or publication by you of any
              portion of our Website is strictly prohibited. Your use of our
              Website does not grant you ownership rights of any kind in our
              Content or Website. As mentioned, we do not claim ownership of
              your Member Content, but by providing it to our Website, you do
              not receive any other rights in our Content other than what
              belongs to you already.
            </p>
            <h3>Linking to Our Website</h3>
            <p>
              You may provide links to our Website, provided (a) that you do not
              remove or obscure, by framing or otherwise, any portion of our
              Website, (b) your Website does not engage in illegal or immoral
              activities, and (c) you discontinue providing links to our Website
              immediately upon request by us.
            </p>
            <h3>Links to Other Websites</h3>
            <p>
              Our Website may contain links to third party websites (“Third
              Party Websites”). These links are provided solely as a convenience
              to you. By linking to these Third Party Websites, we do not create
              or have an affiliation with, or sponsor such Third Party Websites.
              The inclusion of links within our Website does not constitute any
              endorsement, guarantee, warranty, or recommendation of such Third
              Party Websites. Sportifying India has no control over the legal
              documents and privacy practices of third party websites; as such,
              you access any such Third Party Websites at your own risk.
            </p>
            <h3>Warranty Disclaimer</h3>
            <p>
              Sportifying India reserves the right to change any and all Content
              and features of our Website, at any time without notice. Our
              Website may be temporarily unavailable from time to time for
              maintenance or other reasons. Sportifying India assumes no
              responsibility for any error, omission, interruption, deletion,
              defect, delay in operation or transmission, communications line
              failure, theft or destruction or unauthorized access to, or
              alteration of, Member Content.
            </p>
            <p>
              Sportifying India Is not responsible for any technical malfunction
              or other problems of any telephone network or service, computer
              systems, servers, computer or mobile phone equipment, Website,
              failure of email or players on account of technical problems or
              traffic congestion on the Internet or any combination thereof,
              including injury or damage to anyone's computer, mobile phone, or
              other hardware or Website, related to or resulting from using,
              uploading, or downloading materials in connection with our
              Website. Under no circumstances will Sportifying India be
              responsible for any loss or damage, including any loss or damage
              or personal injury or death, resulting from anyone's use of our
              Website, or for any interactions between Users of our Website,
              whether online or offline.
            </p>
            <p>
              OUR WEBSITE IS PROVIDED “AS-IS,” “AS AVAILABLE,” WITH “ALL
              FAULTS”, AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE DISCLAIMED
              (INCLUDING BUT NOT LIMITED TO THE DISCLAIMER OF ANY IMPLIED
              WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE). OUR WEBSITE MAY CONTAIN BUGS, ERRORS, PROBLEMS OR OTHER
              LIMITATIONS. SPORTIFYING INDIA, INCLUDING ALL OUR AFFILIATES, HAVE
              NO LIABILITY WHATSOEVER FOR YOUR USE OF OUR WEBSITE, OTHER THAN AS
              SPECIFIED IN OUR LEGAL TERMS.SPORTIFYINGIND SPORTS LLP CANNOT
              GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF
              OUR WEBSITE. SPORTIFYING INDIA DOES NOT REPRESENT OR WARRANT THAT
              OUR WEBSITE IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE
              OR THAT IT IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
              THEREFORE, YOU SHOULD EXERCISE CAUTION IN THE USE AND DOWNLOADING
              OF ANY SUCH CONTENT AND USE INDUSTRY-RECOGNIZED PROGRAMS TO DETECT
              AND REMOVE VIRUSES. ALL RESPONSIBILITY OR LIABILITY FOR ANY
              DAMAGES CAUSED BY VIRUSES SOMEHOW ATTRIBUTED TO OUR WEBSITE IS
              DISCLAIMED.
            </p>
            <p>
              WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU
              USE OUR WEBSITE AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY
              RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO YOU, YOUR
              MOBILE DEVICE OR COMPUTER SYSTEM, OR OTHER HARM OF ANY KIND THAT
              MAY RESULT. WE, AS WELL AS ALL OF OUR AFFILIATES, ARE NOT LIABLE
              FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES
              (INCLUDING DAMAGES FOR LOSS OF INCOME, BUSINESS, PROFITS,
              LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT,
              BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY
              OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              THE NEGATION AND LIMITATION OF DAMAGES SET FORTH ABOVE ARE
              FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND
              SPORTIFYING INDIA. OUR WEBSITE WOULD NOT BE PROVIDED WITHOUT SUCH
              LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
              OBTAINED BY YOU FROM US THROUGH OUR WEBSITE SHALL CREATE ANY
              WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN OUR
              LEGAL TERMS.
            </p>
            <h3>Limitation of Liability</h3>
            <p>
              Sportifying India, as well as all our affiliates, shall not be
              liable for any loss, injury, claim, liability, or damage of any
              kind resulting in any way from (a) any errors in or omissions from
              our Website; (b) the unavailability or interruption of our
              Website; (c) your use of our Website; or (d) any delay or failure
              in performance of our Website.
            </p>
            <p>
              SPORTIFYING INDIA AND ITS AFFILIATES ARE NOT LIABLE FOR THE ACTS,
              ERRORS, OMISSIONS, REPRESENTATIONS, WARRANTIES, BREACHES OR
              NEGLIGENCE OR FOR ANY PERSONAL INJURIES, DEATH, PROPERTY DAMAGE,
              OR OTHER DAMAGES OR EXPENSES RESULTING THEREFROM. SPORTIFYING
              INDIA AND ITS AFFILIATES HAVE NO LIABILITY AND WILL MAKE NO
              WARRANTY, REFUND, OR OTHER RESTITUTION TO YOU WITH REGARDS TO OUR
              WEBSITE, OTHER THAN AS SPECIFIED HEREIN, FOR ANY REASON,
              INCLUDING, BUT NOT LIMITED TO, DELAYS, CANCELLATIONS, STRIKES,
              GOVERNMENTAL ISSUES, OR FORCE MAJEURE.
            </p>
            <p>
              IN NO EVENT WILL SPORTIFYING INDIA OR ITS DIRECTORS, EMPLOYEES OR
              AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES,
              INCLUDING FOR ANY LOSSES ARISING FROM YOUR USE OF OUR WEBSITE,
              EVEN IF SPORTIFYING INDIA IS AWARE OR HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
              CONTRARY CONTAINED HEREIN, SPORTIFYING INDIA'S LIABILITY TO YOU
              FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE
              ACTION, WILL AT ALL TIMES BE LIMITED TO YOUR CEASING USE OF OUR
              WEBSITE.
            </p>
            <h3>Fees</h3>
            <p>
              If at some point you pay to use our Website, you represent and
              warrant that when you make any payments to Sportifying India for
              such use:
              <ul>
                <li>
                  You agree to pay the fees at our then-current prices as posted
                  on our website, including any applicable taxes
                </li>
                <li>
                  You agree to provide true and complete credit information;
                </li>
                <li>
                  You agree that fee will be honored by your bank or credit card
                  company; AND
                </li>
                <li>
                  You agree that if your initial payment method is dishonored,
                  you will still pay the charges incurred, including any
                  surcharge we may incur due to such dishonored payment.
                </li>
              </ul>
            </p>
            <p>
              All purchases and reservations made on Sportifying India are final
              and non-refundable. In the event of a cancellation or request for
              modification of the reservation, Sportifying India and the service
              suppliers are not obliged to honor such requests and the entire
              amount paid is forfeited by the customer.
            </p>
            <p>
              Refunds, if any, are provided at the sole discretion of
              Sportifying India and the service suppliers.
            </p>
            <h3>Use of Information</h3>
            <p>
              We reserve the right, and you authorize us, to the use and
              assignment of all of your Profile information and usage
              information regarding our Website in any manner consistent with
              our Privacy Policy. All remarks, suggestions, ideas, graphics, or
              other information communicated by you to us (collectively,
              “Submission”) are considered our property. To the extent that such
              Submission contains copyrighted, either owned by you or licensed
              to you, you grant Sportifying India a perpetual, irrevocable,
              royalty-free, worldwide license to use such Submission as we see
              fit, in any form whether on our Website or elsewhere. We will not
              be required to treat any Submission as confidential, and will not
              be liable for any ideas (including without limitation, product,
              service or advertising ideas) and will not incur any liability as
              a result of any similarities that may appear in our Service or
              other operations.
            </p>
            <p>
              Without limitation, we will have exclusive ownership of all
              present and future existing rights to the Submission of every kind
              and nature everywhere. We will be entitled to use the Submission
              for any commercial or other purpose whatsoever, without
              compensation to you or any other person sending the Submission.
              You acknowledge that you are responsible for whatever material you
              submit, and you, not us, have full responsibility for the message,
              including its legality, reliability, appropriateness, originality,
              and copyright.
            </p>
            <h3>Termination of Your Account</h3>
            <p>
              You acknowledge that if you violate the terms of our Legal Terms,
              Sportifying India reserves the right to terminate your account
              without notice. You may also voluntarily terminate your account.
              You understand that if your account is terminated, you will lose
              access to our Website and any Member Content you have provided.
              You understand that we are not required to provide you with copies
              of such Member Content nor continue to maintain copies of such
              Member Content on our Website.
            </p>
            <h3>Arbitration</h3>
            <p>
              Any legal controversy or legal claim arising out of or relating to
              our Legal Terms and/or our Website, excluding legal action taken
              by us to collect or recover damages for, or obtain any injunction
              relating to intellectual property infringement, shall be settled
              solely by binding arbitration in accordance with the Indian
              Council of Arbitration(ICA) depending on the product sale by any
              of the Sportifying India offices.
            </p>
            <p>
              Any such controversy or claim shall be arbitrated on an individual
              basis, and shall not be consolidated in any arbitration with any
              claim or controversy of any other party. The arbitration shall be
              conducted before an arbitrator selected by Indian Council of
              Arbitration(ICA) depending on the product sale to any of the
              Sportifying India offices / companies The judgment of such
              arbitrator on award may be entered into any court having
              jurisdiction thereof.
            </p>
            <p>
              We may seek any interim or preliminary relief from a court of
              competent jurisdiction within Chennai, Tamil Nadu as necessary to
              protect the rights or property of you and us pending the
              completion of arbitration. Each party shall bear one-half of the
              arbitration fees and costs, but the prevailing party may seek
              return of such arbitration fees and reasonable attorney fees.
            </p>
            <h3>Promotions</h3>
            <p>
              Sportifying India and its partners may, from time to time, choose
              to run certain marketing promotions. These promotions may be made
              available to some or all customers at the discretion of the
              company.
            </p>
            <p>
              <strong>Promotion Code:</strong> If you have a valid promotion
              code, you may use this during checkout to avail the necessary
              offer. The promotion discount, if applicable, will be deducted
              directly from your final price payable. Promotions are governed by
              the terms and conditions of individual offers and Sportifying
              India reserves the right to cancel any orders that are found to be
              violating these terms. Customers who repeatedly abuse promotion
              terms may, at the discretion of the company, find their accounts
              blocked and they will be unable to reserve on the Sportifying
              India platform any further.
            </p>
            <p>
              Sportifying India Credits can be earned on completing eligible
              purchases, successfully referring a new customer or completing
              other eligible activities for earning such credits as determined
              by Sportifying India. SPI Credits can be applied to any future
              reservation up to the full amount of the order. Sportifying India
              Credits cannot be paid out in cash or used to pay for an existing
              reservation.
            </p>
            <p>
              If a customer cancels the original reservation they received
              credits for, the associated SPI automatically expires in a certain
              duration as stated when provided after they are credited to your
              account. No refund is provided for any expired or unused credits.
            </p>
            <h3>General Terms</h3>
            <p>Sportifying India is based out of India.</p>
            <p>
              The terms of Indian identity shall be treated as though they were
              executed and performed by Indian laws, the jurisdiction will be
              based out of Chennai, Tamil Nadu, India. The Legal Terms shall be
              governed by and construed in accordance with the laws of India.
            </p>
            <p>
              To the extent that any Content in our Website conflicts or is
              inconsistent with our Legal Terms, our Legal Terms shall take
              precedence. Our failure to enforce any provision of our Legal
              Terms shall not be deemed a waiver of such provision nor of the
              right to enforce such provision. The rights of Sportifying India
              under our Legal Terms shall survive the termination of our Legal
              Terms.
            </p>
          </div>
        </div>

        {/* <Form
          className={styles.form}
          big
          value={search}
          setValue={setSearch}
          onSubmit={() => handleSubmit()}
          placeholder="Search anything"
          type="text"
          name="search"
          icon="search"
        /> */}
      </div>
    </div>
  );
};

export default Main;
