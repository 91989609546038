import React from "react";
import styles from "./Home.module.sass";
import Main from "./Main";
import Package from "./Package";
import Adventure from "../../components/Adventure";
import Travel from "../../components/Travel";
import Work from "./Work";
import Live from "../../components/Live";
import Catalog from "./Catalog";
import Planning from "../../components/Planning";
import Browse from "../../components/Browse";
import Places from "../../components/Places";
import Hosts from "../../components/Hosts";
import View from "./View";
import Faq from "./Faq";
import About from "./About";
import Categories from "../../components/Categories";

import { browse1 } from "../../mocks/browse";
import { categories1 } from "../../mocks/categories";

const Home = () => {
  return (
    <>
      <Main />
      <Catalog />
      <Places
        classSection="section-mb80"
        title="Explore Experiences"
        info="10,789 beautiful places to go"
      />
      <Package />
      <Travel />
      {/* <Work /> */}
      <Faq />
      <Hosts />
      <Browse
        classSection="section"
        classTitle="h2"
        title="Browse Unfiltered Photos"
        info="Happy times come and go, but the memories stay forever"
        items={browse1}
      />
      <About />
      <View />
      {/* <Live title="Live anywhere" /> */}
      {/* <Adventure /> */}
      {/* <Planning title="Travel to make memories all around the world" /> */}
      {/* <Places title="Explore nearby" info="10,789 beautiful places to go" /> */}
      {/* <Categories
        classSection="section"
        title="Browse by category"
        info="Let’s go on an adventure"
        items={categories1}
      /> */}
    </>
  );
};

export default Home;
