import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./View.module.sass";
import Play from "../../../components/Play";

const View = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={styles.details}>
            {/* <div className={cn("stage", styles.stage)}>fleet travel ui kit</div> */}
            <h2 className={cn("h2", styles.title)}>
              Still confused?
              <br />
              Speak with our expert
            </h2>
          </div>
          <a
            className={cn("button", styles.button)}
            href="https://wa.me/+917603848498?text=Hey%20Sportifying%20India,%20I%20want%20more%20information%20on%20various%20experience%20provided%20by%20you.%20Thanks"
          >
            Chat with us
          </a>
        </div>
        <div className={styles.body}>
          <div className={styles.preview}>
            {/* <img src="/images/content/video-bg.jpg" alt="Video bg" /> */}
            <div>
              <iframe
                width="960"
                height="715"
                className={styles.iframe}
                src="https://www.youtube.com/embed/Alwfi_RPMi0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            {/* <Play className="play" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
