import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Receipt.module.sass";
import Icon from "../Icon";

const Receipt = ({
  className,
  items,
  children,
  priceOld,
  priceActual,
  time,
}) => {
  // useEffect(() => {
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // });
  /* Method that will fix header after a specific scrollable */
  // const isSticky = (e) => {
  //   const receipt = document.querySelector(".receipt");
  //   const scrollTop = window.scrollY;
  //   scrollTop >= 1620
  //     ? receipt.classList.add(styles.is_sticky)
  //     : receipt.classList.remove(styles.is_sticky);
  // };

  return (
    // Overflow property of Parent component Page was made visible for the sticky property to work
    // Reminder to revert back in case of any issue
    <div className={cn("className", styles.receipt)}>
      <div
        className={cn(styles.description, {
          // [styles.flex]: items.length > 1,
        })}
      >
        {items.map((x, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.icon}>
              <Icon name={x.icon} size="24" />
            </div>
            <div className={styles.box}>
              <div className={styles.category}>{x.category}</div>
              <div className={styles.subtitle}>{x.title}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.cost}>
        {/* <div className={styles.old}>{priceOld}</div> */}
        <div className={styles.actual}>{priceActual}</div>
        {/* <div className={styles.note}>/{time}</div> */}
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default Receipt;
