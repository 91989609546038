import React, { useState } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <h1 className={cn("hero", styles.title)}>Refund & Cancellation</h1>
        <br />
        <br />
        <div className={styles.egLggN}>
          <div>
            <p>
              Our focus is complete customer satisfaction. In the event, if you
              are displeased with the services provided, we will refund back the
              money, provided the reasons are genuine and proved after
              investigation. Please read the fine prints of each deal before
              buying it, it provides all the details about the services or the
              product you purchase.
            </p>
            <p>
              In case of dissatisfaction from our services, clients have the
              liberty to cancel their projects and request a refund from us. Our
              Policy for the cancellation and refund will be as follows:
            </p>
            <p>
              <h3>
                Sportifying India Standard Cancellation Policy when you cancel a
                Sporting experiences/activities/events :
              </h3>
              <ol>
                <li>
                  {" "}
                  Cancellation 3 days before the start date of the Sporting
                  experiences/activities/events — Get a cash refund with 10%
                  cancellation charges
                </li>
                <li>
                  {" "}
                  Cancellation between 2 days before the start date of the
                  Sporting experiences/activities/events — Get a cash refund
                  with 30% cancellation charges.
                </li>
                <li>
                  Cancellation 1 day before the start date of the Sporting
                  experiences/activities/events — Get a refund with 50%
                  cancellation charges.
                </li>
                <li>
                  Cancellation on the start day of the Sporting
                  experiences/activities/events , or no show on the start day of
                  the Sporting experiences/activities/events — Unfortunately, no
                  refund or voucher.
                </li>
                <li>
                  If you used a voucher to book for the Sporting
                  experiences/activities/events or offloading, the same
                  cancellation percentage will be applicable and the remaining
                  voucher will be credited back to your account
                </li>
              </ol>
            </p>
            <p>
              <h3>
                In the rare event that Sportifying India calls off/cancels a
                Sporting experiences/activities/events :
              </h3>
              <p>
                We almost never call off/cancel our Sporting
                experiences/activities/events. But in the rare event that we
                call off/cancel a Sporting experiences/activities/events because
                of force majeure, OR natural calamities (continuous rain or
                storm, unfavorable wind conditions, bad sea condition,
                snowstorms, thunderstorms, floods, landslides, earthquakes or
                any other natural calamity that prevents us from conducting a
                Sporting experiences/activities/events safely), OR unexpected
                situations like political unrest, curfews, local riots,
                pandemics, lockdowns, government orders OR any other issues that
                affect the safety of the whole group, Sportifying India will
                issue a voucher for the full amount of your Sporting
                experiences/activities/events fee (minus the Sporting
                experiences/activities/events insurance). You can redeem the SPI
                credit on any of our Sporting experiences/activities/events over
                the next one year.
              </p>
              <p>
                <strong>Important Note 1:</strong> If your entire group is
                forced to abandon the Sporting experiences/activities/events
                after you have reached one of our sporting locations or any
                Sporting experiences/activities/events (could be due to
                weather/sudden unforeseen circumstances), we will not issue a
                voucher.{" "}
              </p>
              <p>
                <strong>Important Note 2:</strong> We do not issue vouchers to
                those who cannot experience Sporting
                experiences/activities/events . This can happen when your Sports
                leader denies you the activities due to any of these reasons:
                lack of fitness, non maintenance of turn-around-time, altitude
                sickness, blood pressure, or any other health issues OR if
                you’re found drinking/smoking or breaking rules of the Sporting
                experiences/activities/events OR if you quit the Sporting
                experiences/activities/events on your own due to personal
                reasons. Vouchers are given only when the whole team’s Sporting
                experiences/activities/events is cancelled/called off by
                Sportifying India .
              </p>
            </p>
            <p>
              <h3>
                In the rare event that we shift a Sporting
                experiences/activities/events :
              </h3>
              <p>
                There are situations that are beyond our control — excessive
                snow/rain, thunderstorms, floods, landslides, Bad weather,
                unfavorable wind conditions, undsafe sea conditions, earthquakes
                OR unexpected political unrest, curfews, local riots,
                pandemics,government order, port order, suppliers order changes
                in permission rules of the government or government orders, or
                any such reason where a Sporting experiences/activities/events
                cannot run.
              </p>
              <p>
                Then in such circumstances Sportifying India will always try to
                give you an alternate Sporting experiences/activities/events .
                This may involve shifting you to another Sporting
                experiences/activities/events where the situation is more
                favorable. In such situations, we will issue a voucher for the
                difference in the amount of your Sporting
                experiences/activities/events fee (if applicable).
              </p>
              <p>
                You can redeem the SPI credit on any of our Sporting
                experiences/activities/events over the next one year. A refund
                for the Sporting experiences/activities/events fee or the
                difference is not applicable.
              </p>
            </p>
            <p>
              <h3>
                How to cancel your Sporting experiences/activities/events :{" "}
              </h3>
              <p>
                In case, you wish to cancel your Sporting
                experiences/activities/events , follow these steps:
                {/* <ol>
                  <li>.</li>
                </ol> */}
              </p>
              <p>
                If your Sporting experiences/activities/events payment was made
                using an Sportifying India Credit, you'll need to write an email
                to your Experience Coordinator for the cancellation of your
                Sporting experiences/activities/events{" "}
              </p>
            </p>
            <p>
              <h3>What are the cancellation policies at Sportifying India? </h3>
              <p>
                Sportifying India is a booking platform and Cancellation
                policies will be as per the supplier terms, we request you to
                check the cancellation policy on tour package page on our
                website. To proceed with cancellations you may go ahead and
                raise a support ticket at Contact us link/ cancellation page{" "}
              </p>
            </p>
            <p>
              <h3>
                How much time does the process of refund take at Sportifying
                India?
              </h3>
              <p>
                Depending on booking policies mentioned on the booking page your
                refund will be initiated within 24 hours, the amount will be
                credited to the same account through which you had paid, It
                generally takes anywhere between 7-10 business days. This is the
                standard time frame required by banks and the payment gateway to
                facilitate the transaction. We appreciate your patience and look
                forward to serving you. In case you haven't received the refund
                after this time frame, Please raise a Support ticket at Contact
                page link or the cancellation page{" "}
              </p>
            </p>
          </div>
          {/* <section>

            <ol>
              <li>
                Under the Terms &amp; Conditions that Sellers agree upon,
                products are returnable within the return window if a user
                receives a product that is physically damaged, has missing parts
                or accessories, defective or different from their description on
                the product detail page. All products listed under a particular
                category may have different returns policy. Users are required
                to check &amp; verify the return policy before ordering a
                product.
              </li>
              <li>
                Sportifying India is only responsible for arranging
                troubleshooting of the users' complaints and directing the users
                to the Sellers. The ultimate responsibility of the replacement
                or refund lies with the Sellers.
              </li>
              <li>
                Return will only be processed if:
                <ul className={styles.ul}>
                  <li>
                    It is determined that the product was not damaged while in
                    your possession;
                  </li>
                  <li>
                    The product is not different from what was shipped to you;
                    and
                  </li>
                  <li>
                    The product is returned in condition that it was received
                    including original box, MRP tag, user manual, warranty card
                    and all accessories)
                  </li>
                </ul>
              </li>
              <li>
                In case a user wants to return an electronic device that stores
                personal information, the user himself/herself shall be
                responsible for ensuring that all personal information stored in
                the device is deleted and the device is returned in factory
                settings. Sportifying India will not be liable in any manner for
                any misuse or usage of such information.
              </li>
              <li>
                For all products, the returns/replacement policy provided on the
                product page shall prevail over the general returns policy. Do
                refer the respective item's applicable return/replacement policy
                on the product page for any exceptions to this returns policy.
                In certain cases, a product may be ‘non-returnable’. In such
                cases, the product page will specifically highlight this and the
                user cannot claim for a return/replacement at a later stage.
              </li>
              <li>
                In case the Seller is unable to provide a replacement for the
                product, in that case a refund will be provided.
              </li>
              <li>
                During a return/replacement pickup, the product will be checked
                for the following:
                <ul className={styles.ul}>
                  <li>
                    Correct &amp; complete product: The same
                    IMEI/Name/Brand/Serial number should be clearly visible
                    along with all the accessories etc..
                  </li>
                  <li>
                    The product should be unused, unwashed, unsoiled, without
                    any stains and with non-tampered quality checks/warranty
                    seals (wherever applicable)
                  </li>
                  <li>
                    The product and packaging should not be damaged and should
                    be in the condition that it was received.
                  </li>
                  <li>
                    The pick up executive is free to refuse to accept the
                    product for pick-up in case the above conditions are not
                    met.
                  </li>
                </ul>
              </li>
              <li>
                For category wise understanding of our return, replacement and
                refund policy please check here.
              </li>
            </ol>
          </section> */}
        </div>
      </div>
    </div>
  );
};

export default Main;
