import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Location.module.sass";
import Icon from "../Icon";

const Location = ({
  className,
  icon,
  description,
  placeholder,
  small,
  bodyDown,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className={styles.content}
      // className={cn(
      //   className,
      //   styles.location,
      //   { [styles.small]: small },
      //   { [styles.bodyDown]: bodyDown },
      //   { [styles.active]: visible }
      // )}
    >
      {/* <div className={styles.head}> */}
      {/* <div className={styles.icon}>
          <Icon name={icon} size="24" />
        </div> */}
      <div className={styles.input}>{placeholder}</div>
      <div className={styles.description}>{description}</div>
      {/* <button className={styles.clear} onClick={() => setVisible(false)}>
            <Icon name="close-circle" size="24" />
          </button> */}
      {/* </div> */}
      {/* <div className={styles.body}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                {x}
              </div>
            ))}
          </div>
        </div> */}
    </div>
  );
};

export default Location;
