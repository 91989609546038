import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../../components/Icon";
import Hosts from "../../../../components/Hosts2";
import Comment from "../../../../components/Testimonial";
import Faq from "./Faq";

const options = [
  {
    title: "Your safety",
    icon: "candlesticks",
    color: "#92A5EF",
  },
  {
    title: "Surfboard and gear ",
    icon: "car",
    color: "#8BC5E5",
  },
  {
    title: "Professional instructors",
    icon: "user",
    color: "#FA8F54",
  },
];

const content = [
  {
    title: "Some enthusiasm and positive energy ",
    icon: "modem",
  },
  {
    title: "A rash vest ideally/Athleisure wear ",
    icon: "toilet-paper",
  },
  {
    title: "Change of clothes ",
    icon: "monitor",
  },
  {
    title: "Cap and shades (optional)",
    icon: "burger",
  },
];

const Details = ({ className, vendor }) => {
  return (
    <div className={cn(className, styles.details)}>
      <p>{vendor.content}</p>
      <br />
      <div className={styles.profile}>
        <span>Organised by</span>
        {/* <div className={styles.avatar}>
          <img src="/images/content/avatar.jpg" alt="Avatar" />
        </div> */}
        <div className={styles.name}>{vendor.title}</div>
      </div>
      <h4 className={cn("h4", styles.title)}>What’s Included</h4>
      <div className={styles.options}>
        {vendor.inclusion.map((x, index) => (
          <div className={styles.option} key={index}>
            <div className={styles.icon} style={{ borderColor: "blue" }}>
              <Icon name={"tick"} size="24" />
            </div>
            <div className={styles.text}>{x}</div>
          </div>
        ))}
      </div>
      <div className={styles.group}>
        <div className={styles.box}>
          <div className={styles.content}>
            <h4>Rough agenda</h4>
            <p>{vendor.agenda}</p>
          </div>
          {/* <button className={cn("button-stroke button-small", styles.button)}>
            Show more
          </button> */}
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.box}>
          <div className={styles.content}>
            <h4>Pre-requisites:</h4>
            <ul>
              {vendor.prerequisites.map((li) => (
                <li>{li}</li>
              ))}
            </ul>
          </div>
          {/* <button className={cn("button-stroke button-small", styles.button)}>
            Show more
          </button> */}
        </div>
      </div>
      {/* <div className={styles.group}>
        <div className={styles.box}>
          <div className={styles.content}>
            <h4>What's included? </h4>
            <ul>
              {vendor.inclusion.split("\n").map((li) => (
                <li>{li}</li>
              ))}
            </ul>
          </div>
        </div>
      </div> */}
      {vendor?.testimonials && (
        <div className={styles.group}>
          <div className={styles.box}>
            <div className={styles.content}>
              <h4>User Reviews</h4>
              <Comment testimonials={vendor?.testimonials} />
            </div>
          </div>
        </div>
      )}
      <div className={styles.group}>
        <div className={styles.box}>
          <div className={styles.content}>
            <Faq items={vendor?.faq} />
          </div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.box}>
          <div className={styles.content}>
            <h4>What should you carry?</h4>
            {content.map((x, index) => (
              <p>
                <Icon name={x.icon} size="24" /> {x.title}{" "}
              </p>
            ))}
          </div>
          {/* <button className={cn("button-stroke button-small", styles.button)}>
            Show more
          </button> */}
        </div>
      </div>
      {/* <div className={styles.group}>
        <div className={styles.box}>
          <div className={styles.content}>
            <h4>Payment and Cancellation Policies</h4>
            <ul>
              <li>Rashvests (ideal), or athleisure wear </li>
              <li>Change of clothes </li>
              <li>Cap and shades </li>
              <li>Non grip shoes(ideal), or sports shoes/barefoot</li>
            </ul>
          </div>
          <button className={cn("button-stroke button-small", styles.button)}>
            Show more
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Details;
