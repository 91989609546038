import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./CreditCard.module.sass";
import TextInput from "../../TextInput";
import TextArea from "../../TextArea";
import Checkbox from "../../Checkbox";
import axiosInstance from "../../../utils/axiosInstance";
import axios from "axios";

const cards = [
  {
    image: "./images/content/visa.svg",
    alt: "Visa",
  },
  {
    image: "./images/content/master-card.svg",
    alt: "Master Card",
  },
];

// const loadRazorpay = (src) => {
//   return new Promise((resolve) => {
//     const script = document.createElement("script");
//     script.src = src;
//     script.onload = () => resolve(true);
//     script.onerror = () => resolve(false);
//     document.body.appendChild(script);
//   });
// };

const CreditCard = ({ className, buttonUrl }) => {
  const [save, setSave] = useState(true);
  const [order, setOrder] = useState({});
  const [data, setData] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getinfo = async () => {
      axios.defaults.headers.common = {
        Authorization: `Bearer shubhendu.verma8083@gmail.com`,
      };
      const {
        data: { order },
      } = await axios.post("https://sail.sportifyingindia.com/v1/paytm/order", {
        product_id: "18fc84de-8967-4137-ad55-37a22586ff9f",
      });
      setOrder({
        ...order,
        // mid: "jPryat07068997778482",
      });
    };
    getinfo();
  }, []);

  // const displayRazorpay = async () => {
  //   const res = await loadRazorpay(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );
  //   if (!res) {
  //     alert("Razorpay is not loaded");
  //     return;
  //   }
  //   const token = localStorage.getItem("token");
  //   axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
  //   const postBody = { product_id: "fd0e0325-fc61-468f-8b7d-85406103d9b0" };
  //   const response = await axiosInstance.post("/v1/pay/order/", postBody);

  //   // if(response.status === 201) {
  //   //   const { key,order, user } = response.data;
  //   // }

  //   const { key, order, user } = response.data;
  //   const options = {
  //     key,
  //     amount: order.amount,
  //     currency: order.currency,
  //     name: "Sportifying India",
  //     description: "Test Transaction",
  //     // image: "https://example.com/your_logo",
  //     order_id: order.id,
  //     handler: function (response) {
  //       alert(response.razorpay_payment_id);
  //       alert(response.razorpay_order_id);
  //       alert(response.razorpay_signature);
  //     },
  //     prefill: {
  //       name: user.full_name,
  //       email: user.email,
  //       contact: user.phone_no,
  //     },
  //     // notes: {
  //     //   address: "Razorpay Corporate Office",
  //     // },
  //     // theme: {
  //     //   color: "#3399cc",
  //     // },
  //   };
  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // };

  const onSubmit = async () => {
    const _data = {
      full_name: data.full_name,
      email: data.email,
      phone: data.phone,
      message: data.message,
      activity_name: "Kayak",
      activity_category: "Learn",
      activity_cost: "14000",
    };
    axios.defaults.headers.common = {
      Authorization: `Bearer shubhendu.verma8083@gmail.com`,
    };
    const resp = await axios.post(
      `https://django-server-production-eb79.up.railway.app/api/v1/enquiry-create/`,
      _data
      // {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // }
    );
  };
  // const onUPIpayment = async () => {
  //   const data = {
  //     head: { txnToken: order.txnToken },
  //     body: {
  //       requestType: "NATIVE",
  //       mid: order.mid,
  //       orderId: order.id,
  //       paymentMode: "UPI",
  //       payerAccount: "7777777777@paytm",
  //     },
  //   };
  //   axios.defaults.headers.common = {
  //     Authorization: `Bearer shubhendu.verma8083@gmail.com`,
  //   };
  //   const resp = await axios.post(
  //     `https://securegw.paytm.in/theia/api/v1/processTransaction?mid=${order?.mid}&orderId=${order?.id}`,
  //     data
  //     // {
  //     //   headers: {
  //     //     "Content-Type": "application/json",
  //     //   },
  //     // }
  //   );
  // };

  return (
    <div className={cn(className, styles.confirm)}>
      {/* <div className={styles.line}>
        <div className={styles.subtitle}>Credit Card</div>
        <div className={styles.cards}>
          {cards.map((x, index) => (
            <div className={styles.card} key={index}>
              <img src={x.image} alt={x.alt} />
            </div>
          ))}
        </div>
      </div> */}
      {/* <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="card number"
          name="card"
          type="tel"
          placeholder="XXXX XXXX XXXX XXXX"
          required
        />
        <TextInput
          className={styles.field}
          label="card holder"
          name="holder"
          type="text"
          placeholder="TRAN MAU TRI TAM"
          required
        />
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="EXPIRATION DATE"
            name="date"
            type="tel"
            placeholder="MM / YY"
            required
          />
          <TextInput
            className={styles.field}
            label="CVC"
            name="cvc"
            type="tel"
            placeholder="CVC"
            required
          />
        </div>
      </div> */}
      {/* <form
        method="post"
        type="redirect"
        action={`https://securegw-stage.paytm.in/theia/api/v1/processTransaction?mid=${order?.mid}&orderId=${order?.id}`}
      >
        <input type="text" name="mid" value={order?.mid} />
        <input type="text" name="orderId" value={order?.id} />
        <input type="text" name="txnToken" value={order?.txnToken} />
        <input type="text" name="paymentMode" value="UPI" />
        <input type="text" name="requestType" value="NATIVE" />
        <input type="text" name="payerAccount" value="7777777777@paytm" /> */}
      {/* <input type="submit" /> */}

      {/* <Checkbox
        className={styles.checkbox}
        value={save}
        onChange={() => setSave(!save)}
        content="Save Card"
      /> */}
      <TextInput
        className={styles.field}
        type="text"
        name="full_name"
        label="Full Name"
        onChange={handleChange}
      />
      <TextInput
        className={styles.field}
        type="text"
        name="email"
        label="Email Address"
        onChange={handleChange}
      />
      <TextInput
        className={styles.field}
        type="text"
        name="phone"
        label="Phone Number"
        onChange={handleChange}
      />
      <div className={styles.message}>
        <div className={styles.category}>Message the host</div>
        <TextArea
          className={styles.field}
          name="message"
          placeholder="I will be late about 1 hour, please wait..."
          onChange={handleChange}
        />
      </div>
      <button className={cn("button", styles.button)} onClick={onSubmit}>
        Request Callback
      </button>
      {/* </form> */}
    </div>
  );
};

export default CreditCard;
