import React, { useState } from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import Card from "./Card";
import Form from "../../../components/Form";
// data
import { catalogList } from "../../../mocks/catalog";
import { eventData } from "../../../mocks/catalog";
import axiosInstance from "../../../utils/axiosInstance";

const dateOptions = ["Recently added", "Long added"];
const sortingOptions = [];
catalogList.map((x) => sortingOptions.push(x.title));

const Catalog = () => {
  const [date, setDate] = useState(dateOptions[0]);
  const [emailSend, setEmailSend] = useState(false);
  const [email, setEmail] = useState("");
  const [sorting, setSorting] = useState(sortingOptions[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postBody = { email };
    const resp = await axiosInstance.post("/v1/customer/contact_us", postBody);
    if (resp.status === 200) {
      setEmailSend(true);
      setEmail("");
    }
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <h2 className={cn("h2", styles.title)}>Offbeat Events</h2>
          <div className={cn("info", styles.info)}>
            Let’s go on an adventure
          </div>
          {/* <div className={styles.btns}>
            <button className={cn("button-stroke button-small", styles.button)}>
              View all
            </button>
          </div> */}
          {/* <div className={styles.sorting}>
            <div className={styles.nav}>
              {catalogList.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: x.title === sorting,
                  })}
                  onClick={() => setSorting(x.title)}
                  key={index}
                >
                  <Icon name={x.icon} size="16" />
                  {x.title}
                </button>
              ))}
            </div>
          </div> */}
          <div className={styles.list}>
            {catalogList
              .find((x) => x.title === sorting)
              .items.map((x, index) => (
                <Card className={styles.card} item={x} key={index} />
              ))}
          </div>
          <div className={styles.formlist}>
            {!emailSend ? (
              <>
                <div className={styles.info2}>
                  Get access to upcoming offbeat events 🔥
                </div>

                <Form
                  className={styles.form}
                  value={email}
                  setValue={setEmail}
                  onSubmit={handleSubmit}
                  placeholder="Enter your email"
                  type="email"
                  name="email"
                  icon="arrow-next"
                />
              </>
            ) : (
              <div className={styles.info2}>
                Congratulations🎉, You're now on the list! You'll start
                receiving updates from our end
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
