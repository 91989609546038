import React, { useState } from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import Card from "./Card";
import Slider from "react-slick";
import Form from "../../../components/Form";
// data
import { packagesList } from "../../../mocks/packages";
import { eventData } from "../../../mocks/catalog";
import axiosInstance from "../../../utils/axiosInstance";

const dateOptions = ["Recently added", "Long added"];
const sortingOptions = [];
packagesList.map((x) => sortingOptions.push(x.title));

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: (
    <SlickArrow>
      <Icon name="arrow-next" size="14" />
    </SlickArrow>
  ),
  prevArrow: (
    <SlickArrow>
      <Icon name="arrow-prev" size="14" />
    </SlickArrow>
  ),
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 100000,
      settings: "unslick",
    },
  ],
};

const Package = () => {
  const [date, setDate] = useState(dateOptions[0]);
  const [emailSend, setEmailSend] = useState(false);
  const [email, setEmail] = useState("");
  const [sorting, setSorting] = useState(sortingOptions[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postBody = { email };
    const resp = await axiosInstance.post("/v1/customer/contact_us", postBody);
    if (resp.status === 200) {
      setEmailSend(true);
      setEmail("");
    }
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <h2 className={cn("h2", styles.title)}>Offbeat Packages</h2>
          <div className={cn("info", styles.info)}>Keep calm & travel on</div>

          <div className={styles.list}>
            <Slider
              className={cn("places-slider", styles.slider)}
              {...settings}
            >
              {packagesList
                .find((x) => x.title === sorting)
                .items.map((x, index) => (
                  <Card className={styles.card} item={x} key={index} />
                ))}
            </Slider>
          </div>
          <div className={styles.formlist}>
            {!emailSend ? (
              <>
                <div className={styles.info2}>
                  Get access to upcoming offbeat events 🔥
                </div>

                <Form
                  className={styles.form}
                  value={email}
                  setValue={setEmail}
                  onSubmit={handleSubmit}
                  placeholder="Enter your email"
                  type="email"
                  name="email"
                  icon="arrow-next"
                />
              </>
            ) : (
              <div className={styles.info2}>
                Congratulations🎉, You're now on the list! You'll start
                receiving updates from our end
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Package;
