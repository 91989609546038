import React, { useState } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import PersonalInfo from "../PersonalInfo";
const Main = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <h1 className={cn("hero", styles.title)}>Contact Us</h1>
        {/* <div className={styles.info}>
          Last updated on 19 April 2022 Welcome to the Sportifying India club!
          At Sportifying India we are creating a system that strives to reward
          high trust and creditworthy individuals of India, inspiring others to
          be like them.
        </div> */}
        <br />
        <br />
        <PersonalInfo />
      </div>
    </div>
  );
};

export default Main;
