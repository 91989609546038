import React from "react";
import styles from "./ContactUs.module.sass";
import Main from "./Main";

const ContactUs = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default ContactUs;
