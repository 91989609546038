import React from "react";
import cn from "classnames";
import styles from "./SailingCourse.module.sass";
import Product from "../../components/Product";
import Description from "./Description";
import CommentsProduct from "../../components/CommentsProduct";
import Browse from "../../components/Browse";
import Newsletter from "../../components/Newsletter";

// data
import { browse1 } from "../../mocks/browse";
import { browse2 } from "../../mocks/browse";
import { eventData } from "../../mocks/catalog";

const breadcrumbs = [];


const parametersUser = [
  {
    title: "Superhost",
    icon: "home",
  },
  {
    title: "256 reviews",
    icon: "star-outline",
  },
];

const socials = [
  // {
  //   title: "twitter",
  //   url: "https://twitter.com/ui8",
  // },
  {
    title: "instagram",
    url: "https://www.instagram.com/ui8net/",
  },
  {
    title: "facebook",
    url: "https://www.facebook.com/ui8.net/",
  },
];
const SailingCourse = () => {
  var event = null;
  var event_options = []
  eventData.map((x, index) => {
    x.items.map((y, index) => {
      if (y.slug==='sailing-course'){
        event = y
        event_options = [
                {
                  title: event.location,
                  icon: "flag",
                },
              ]
      }
    })
  })
  
  return (
    <>
      <Product
        classSection="section-mb64"
        urlHome="/"
        title={event.detail.event_brief}
        breadcrumbs={breadcrumbs}
        options={event_options}
        gallery={event.detail.gallery}
        type="stays"
        rating={event.rating}
      />
      <Description classSection="section" />
      <CommentsProduct
        className={cn("section", styles.comment)}
        parametersUser={parametersUser}
        info={event.detail.event_desc}
        socials={socials}
        buttonText="Contact"
      />
      <Browse
        classSection="section"
        classTitle="h2"
        title="Browse by property type"
        info="Let’s go on an adventure"
        items={browse1}
      />
      <Newsletter />
      <Browse
        classSection="section"
        headSmall
        classTitle="h4"
        title="Explore mountains in New Zealand"
        items={browse2}
      />
    </>
  );
};

export default SailingCourse;
