import React, { useState } from "react";
import cn from "classnames";
import styles from "./MobileNo.module.sass";
import Icon from "../Icon";
import Form from "../Form";
import TextInput from "../TextInput";
import Radio from "../Radio";
//react google login imports
import { GoogleLogin } from "react-google-login";
import { useGoogleLogin } from "react-google-login";
import GoogleButton from "react-google-button";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const MobileNo = (props) => {
  return (
    <>
      {!props.message ? (
        <div className={styles.item}>
          <div className={cn("h3", styles.title)}>Enter Your Mobile Number</div>
          {/* <div className={styles.info}>We texted your code to +1 234 567 890</div> */}
          <form className={styles.form}>
            {/* <div className={styles.code}> */}
            {/* <div className={styles.number}> */}
            {/* <TextInput
          className={styles.field}
          name="mobile_no"
          type="text"
          placeholder="Mobile Number"
          required
        /> */}
            <Form
              className={styles.form}
              // value={email}
              setValue={props.handleChange}
              onSubmit={props.handleSubmit}
              placeholder="Mobile Number"
              type="text"
              name="mobile_no"
              icon="arrow-next"
            />
            {/* <input
          type="text"
          name="mobile_no"
          class="username form-control"
          placeholder="Enter Your Mobile Number"
        /> */}
            {/* </div> */}
            {/* </div> */}
          </form>
        </div>
      ) : (
        <div className={styles.item}>
          <div className={cn("h3", styles.title)}>
            Thank You for Signing up!
          </div>
          {/* <div className={styles.info}>We texted your code to +1 234 567 890</div> */}
          <form className={styles.form}>
            {/* <input
          type="text"
          name="mobile_no"
          class="username form-control"
          placeholder="Enter Your Mobile Number"
        /> */}
          </form>
        </div>
      )}
    </>
  );
};

export default MobileNo;
